import inject from 'seacreature/lib/inject'
import { useContext } from 'react'
import { Box, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@mui/material'
import { DateTime } from 'luxon'

inject('pod', ({ StateContext }) => {
  inject('route', [
    '/queue',
    p => () => {
      const { queued } = useContext(StateContext)

      const contacts =
        queued?.contacts?.map(c => {
          c.type = 'Contact'
          return c
        }) ?? []
      const canvasses =
        queued?.canvasses?.map(c => {
          c.type = 'Canvass'
          return c
        }) ?? []

      const queue = [...contacts, ...canvasses]

      return (
        <>
          <Box sx={{ padding: '1rem' }}>
            <h2 style={{ margin: 0, marginTop: '1rem' }}>Sync Queue</h2>
          </Box>
          <Box sx={{ padding: '1rem' }}>
            {queue.length} item{queue.length === 1 ? '' : 's'} in queue to sync.
          </Box>
          {queue.length ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ padding: '1rem' }}>
                <TableContainer sx={{ marginTop: '1rem' }}>
                  <Table>
                    <colgroup>
                      <col style={{ width: '20%' }} />
                      <col style={{ width: '60%' }} />
                      <col style={{ width: '20%' }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        {['Type', 'Date', 'Sync Status'].map(h => (
                          <TableCell key={h}>{h}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {queue.map(({ type, id, created_at, updated_at }) => {
                        return (
                          <TableRow key={id}>
                            <TableCell>{type}</TableCell>
                            <TableCell>
                              {DateTime.fromISO(new Date(updated_at || created_at).toISOString()).toFormat(
                                'dd MMMM yyyy'
                              )}
                            </TableCell>
                            <TableCell>In queue</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          ) : null}
        </>
      )
    }
  ])
})
