import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState, useMemo } from 'react'
import page from 'page'
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  useMediaQuery,
  IconButton,
  Fab,
  ListSubheader,
  FormGroup
} from '@mui/material'
import { Cancel, ArrowDropDown, ContactPage } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { useTheme } from '@mui/material/styles'

inject('pod', ({ StateContext }) => {
  inject('route', [
    '/contacts',
    p => () => {
      // const initial_contact_status = localStorage.getItem('filter.contact_status')
      // const initial_tm = localStorage.getItem('filter.tm')
      // const initial_canvass = localStorage.getItem('filter.canvass')
      // const initial_current_customers = localStorage.getItem('filter.current_customers')
      // const initial_filter = {}
      // if (initial_contact_status) initial_filter.contact_status = initial_contact_status
      // if (initial_tm) initial_filter.tm = parseInt(initial_tm)
      // if (initial_canvass) initial_filter.canvass = initial_canvass
      // if (initial_current_customers) initial_filter.current_customers = initial_current_customers

      const { data } = useContext(StateContext)
      // const [state, set_state] = useState({
      //   filters: Object.keys(initial_filter).length > 0 ? initial_filter : { contact_status: 'active' }
      // })
      const [state, set_state] = useState({ filters: { contact_status: 'active' } })
      const [filtered_contacts, set_filtered_contacts] = useState([])
      const [filtered_canvasses, set_filtered_canvasses] = useState([])
      const [filtered_sheds, set_filtered_sheds] = useState([])

      const theme = useTheme()
      const screen__gt_md = useMediaQuery(theme.breakpoints.up('md'))

      const contacts =
        data?.contacts.filter(c => {
          return c.sheds.some(s => s.tm)
        }) ?? []
      const canvasses = useMemo(
        () =>
          data?.canvasses.reduce((acc, c) => {
            if (!acc.some(a => a.shed_id === c.shed_id)) acc.push(c)
            return acc
          }, []) ?? [],
        [data?.canvasses, contacts, state.filters]
      )
      const current_year = DateTime.now().year

      const select_tm = e => {
        localStorage.setItem('filter.tm', e.target.value)
        set_state(s => ({
          ...s,
          filters: { ...s.filters, tm: e.target.value }
        }))
        set_table_page(0)
      }

      const select_canvass = e => {
        localStorage.setItem('filter.canvass', e.target.value)
        set_state(s => ({
          ...s,
          filters: { ...s.filters, canvass: e.target.value }
        }))
        set_table_page(0)
      }

      const select_current_customers = e => {
        localStorage.setItem('filter.current_customers', e.target.value)
        set_state(s => ({
          ...s,
          filters: { ...s.filters, current_customers: e.target.value }
        }))
        set_table_page(0)
      }

      const clear_filter = f => e => {
        localStorage.removeItem(`filter.${f}`)
        set_state(s => ({ ...s, filters: { ...s.filters, [f]: null } }))
        set_table_page(0)
      }

      const [table_page, set_table_page] = useState(0)
      const [rows_per_page, set_rows_per_page] = useState(25)

      const change_table_page = (event, newPage) => {
        set_table_page(newPage)
      }
      const change_table_rows_per_page = event => {
        set_rows_per_page(+event.target.value)
        set_table_page(0)
      }

      useEffect(() => {
        const initial_contact_status = localStorage.getItem('filter.contact_status')
        const initial_tm = localStorage.getItem('filter.tm')
        const initial_canvass = localStorage.getItem('filter.canvass')
        const initial_current_customers = localStorage.getItem('filter.current_customers')
        const initial_filter = {}
        if (initial_contact_status) initial_filter.contact_status = initial_contact_status
        if (initial_tm) initial_filter.tm = parseInt(initial_tm)
        if (initial_canvass) initial_filter.canvass = initial_canvass
        if (initial_current_customers) initial_filter.current_customers = initial_current_customers

        set_state(s => ({
          ...s,
          filters: Object.keys(initial_filter).length > 0 ? initial_filter : { contact_status: 'active' }
        }))
      }, [])

      useEffect(() => {
        const filters = Object.fromEntries(Object.entries(state.filters).filter(([k, v]) => v))
        const current_campaign_canvasses = canvasses.filter(c => {
          const itemYear = DateTime.fromISO(c.updated_at).year
          return DateTime.fromISO(c.updated_at).year == current_year
        })
        const filtered_contacts = contacts
          .filter(c => !filters.contact_status || c.status === filters.contact_status)
          .filter(c => {
            if (filters.tm && !c.sheds.some(s => s.tm?.id === filters.tm)) return false
            return true
          })
          .filter(c => {
            if (filters.canvass === 'not_canvassed') {
              return !c.sheds.every(s => current_campaign_canvasses.find(fc => fc.shed_id == s.shed_id))
            }
            if (filters.canvass === 'canvassed') {
              return c.sheds.every(s => current_campaign_canvasses.find(fc => fc.shed_id == s.shed_id))
            }
            if (filters.canvass === 'canvass_pending') {
              return c.sheds.every(s =>
                current_campaign_canvasses
                  .filter(fc => fc.agreement_status != 'Confirmed')
                  .find(fc => fc.shed_id == s.shed_id)
              )
            }
            if (filters.canvass === 'canvass_confirmed') {
              return c.sheds.every(s =>
                current_campaign_canvasses
                  .filter(fc => fc.agreement_status == 'Confirmed')
                  .find(fc => fc.shed_id == s.shed_id)
              )
            }
            return true
          })
          .filter(c => {
            if (filters.current_customers === 'deosan_customers') {
              return c.deosan_products
            }
            if (filters.current_customers === 'competitor_customers') {
              return !c.deosan_products
            }
            if (filters.current_customers === 'targeted_customers') {
              return c.sheds.some(s => s.is_focus)
            }
            return true
          })
          .filter(c => {
            if (!state.filters.keywords) return true
            const email_address = c.email_address
            const first_name = c.first_name
            const last_name = c.last_name
            const trading_name = c.trading_name
            const phone_number = c.phone_number
            const tags = c.tags.map(t => t.tag).join(' ')
            const sheds = c.sheds.map(s => [s.dairy_number, s.region].join(' ')).join(' ')
            const s = [email_address, first_name, last_name, trading_name, phone_number, tags, sheds]
              .filter(v => v)
              .join(' ')
            return s.toLowerCase().includes(state.filters.keywords.toLowerCase())
          })
        const filtered_canvasses = canvasses
          .filter(c => {
            if (filters.tm) {
              const contact = contacts.find(cc => cc.id === c.contact_id)
              const has_tm = contact?.sheds.some(s => s.tm?.id === filters.tm)
              if (!has_tm) {
                return false
              }
            }
            return true
          })
          .filter(() => {
            if (filters.canvass === 'not_canvassed') {
              return false
            }
            if (filters.canvass === 'canvassed') {
              return true
            }
            return true
          })
          .filter(c => {
            const contact = filtered_contacts.find(cc => cc.sheds.some(s => s.shed_id === c.shed_id))
            if (!contact) {
              return false
            }
            if (filters.current_customers === 'deosan_customers') return contact.deosan_products
            if (filters.current_customers === 'competitor_customers') return !contact.deosan_products
            return true
          })
        const filtered_sheds = filtered_contacts
          .reduce((acc, c) => {
            c.sheds.forEach(s => {
              if (!acc.some(a => a.shed_id === s.shed_id)) acc.push(s)
            })
            return acc
          }, [])
          .filter(s => {
            if (filters.canvass === 'not_canvassed') {
              return canvasses.find(c => c.shed_id == s.shed_id)
            }
            if (filters.canvass === 'canvassed') {
              return canvasses.find(c => c.shed_id == s.shed_id)
            }
            return true
          })

        set_filtered_contacts(() => filtered_contacts ?? [])
        set_filtered_canvasses(() => filtered_canvasses ?? [])
        set_filtered_sheds(() => filtered_sheds ?? [])
        set_table_page(0)
      }, [data, state.filters])

      const sheds_count = filtered_sheds?.length ?? 0
      const progress_for_year = DateTime.now().year
      const filtered_canvasses_this_year = filtered_canvasses?.filter(
        ({ created_at }) => progress_for_year === DateTime.fromISO(created_at).year
      )
      const canvassed_count = filtered_canvasses_this_year?.length ?? 0
      const not_canvassed_count = sheds_count - canvassed_count
      const pending_count = filtered_canvasses_this_year?.filter(c => c.agreement_status === 'Pending').length
      const confirmed_count = filtered_canvasses_this_year?.filter(c => c.agreement_status === 'Confirmed').length
      const no_business_count = filtered_canvasses_this_year?.filter(c => c.agreement_status === 'No Business').length
      const canvassed_percentage = Math.round(canvassed_count ? (canvassed_count / sheds_count) * 100 : 0)
      const not_canvassed_percentage = Math.round(not_canvassed_count ? (not_canvassed_count / sheds_count) * 100 : 0)
      const pending_percentage = pending_count ? (pending_count / canvassed_count) * 100 : 0
      const confirmed_percentage = confirmed_count ? (confirmed_count / canvassed_count) * 100 : 0
      const no_business_percentage = no_business_count ? (no_business_count / canvassed_count) * 100 : 0

      const which_island = area_name => {
        if (!area_name) return null
        if (area_name.toLowerCase().includes('north island')) return 'NI'
        if (area_name.toLowerCase().includes('south island')) return 'SI'
        const island = area_name.split(' ')[0]
        if (island.toLowerCase().includes('training')) return 'NI'
        return island
      }

      const territory_managers_grouped_by_island = data?.territory_managers.reduce(
        (acc, tm) => {
          if (!tm.area_name) return acc
          const island = which_island(tm.area_name)
          if (!island) return acc
          const area_split = tm.area_name.replace(/\d+/g, '').split('-')
          const area = tm.area_name.replace(/\d+/g, '').split('-')[area_split.length - 1].trim()
          const first_name = tm.first_name.replace(/\d+\s-\s/, '')
          const last_name = tm.last_name.replace(/\d+\s-\s/, '')
          acc[island].push({
            id: tm.id,
            area,
            first_name,
            last_name
          })
          return acc
        },
        { NI: [], SI: [] }
      )

      const set_filter = f => e => {
        localStorage.setItem(`filter.${f}`, e.target.value)
        set_state(s => ({
          ...s,
          filters: { ...s.filters, [f]: e.target.value }
        }))
        set_table_page(0)
      }

      const ProgressBar = ({
        heading,
        subheading,
        sheds_count,
        canvassed_count,
        not_canvassed_count,
        pending_count,
        confirmed_count,
        no_business_count,
        canvassed_percentage,
        not_canvassed_percentage,
        pending_percentage,
        confirmed_percentage,
        no_business_percentage,
        scale = 1
      }) => {
        return (
          <Box>
            {heading ? (
              <h3
                style={{
                  margin: '2rem 0 0.5rem 0',
                  fontSize: `${scale * 1.5}rem`
                }}
              >
                {heading}
              </h3>
            ) : null}
            <span
              style={{
                fontSize: `${scale * 1}rem`
              }}
            >
              {subheading}
            </span>
            {sheds_count > 0 ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '1rem 0',
                    height: `${scale * 2}rem`
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: `${canvassed_percentage}%`
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: 'highlight.blue',
                        width: `${pending_percentage}%`
                      }}
                    />
                    <Box
                      sx={{
                        backgroundColor: 'highlight.green',
                        width: `${confirmed_percentage}%`
                      }}
                    />
                    <Box
                      sx={{
                        backgroundColor: 'highlight.yellow',
                        width: `${no_business_percentage}%`
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: 'highlight.silver',
                      width: `${not_canvassed_percentage}%`
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem'
                    }}
                  >
                    <Box
                      sx={{
                        width: '0.75rem',
                        height: '0.75rem',
                        backgroundColor: 'highlight.blue'
                      }}
                    />
                    <span style={{ fontSize: '0.75rem' }}>
                      <span style={{ fontWeight: 'bold' }}>{pending_count}</span> Pending
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem'
                    }}
                  >
                    <Box
                      sx={{
                        width: '0.75rem',
                        height: '0.75rem',
                        backgroundColor: 'highlight.green'
                      }}
                    />
                    <span style={{ fontSize: '0.75rem' }}>
                      <span style={{ fontWeight: 'bold' }}>{confirmed_count}</span> Confirmed
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem'
                    }}
                  >
                    <Box
                      sx={{
                        width: '0.75rem',
                        height: '0.75rem',
                        backgroundColor: 'highlight.yellow'
                      }}
                    />
                    <span style={{ fontSize: '0.75rem' }}>
                      <span style={{ fontWeight: 'bold' }}>{no_business_count}</span> No Business
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem'
                    }}
                  >
                    <Box
                      sx={{
                        width: '0.75rem',
                        height: '0.75rem',
                        backgroundColor: 'highlight.silver'
                      }}
                    />
                    <span style={{ fontSize: '0.75rem' }}>
                      <span style={{ fontWeight: 'bold' }}>{not_canvassed_count}</span> Not Canvassed
                    </span>
                  </Box>
                </Box>
              </>
            ) : null}
          </Box>
        )
      }

      return (
        <>
          <Fab
            variant='extended'
            color='primary'
            onClick={() => page('/canvass/create')}
            sx={{
              position: 'fixed',
              bottom: '2rem',
              right: '2rem',
              zIndex: 1000
            }}
          >
            <ContactPage sx={{ mr: 1 }} />
            New Canvass
          </Fab>
          <FormGroup row sx={{ gap: '1rem', margin: '1rem' }}>
            <FormControl sx={{ marginTop: '1rem' }}>
              <TextField
                label='Search'
                variant='outlined'
                size='small'
                type='search'
                onChange={e =>
                  set_state(s => ({
                    ...s,
                    filters: { ...s.filters, keywords: e.target.value }
                  }))
                }
                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
              />
            </FormControl>
          </FormGroup>
          <FormGroup row sx={{ gap: '1rem', margin: '1rem', flexWrap: 'wrap' }}>
            <FormControl sx={{ flexBasis: 0, flexGrow: 1, minWidth: 200 }}>
              <InputLabel id='contact-status-label' size='small'>
                <b>Contact Status</b>
              </InputLabel>
              <Select
                labelId='contact-status-label'
                id='contact-status'
                value={state.filters?.contact_status ?? 'active'}
                label='Contact Status'
                variant='outlined'
                onChange={set_filter('contact_status')}
                size='small'
                IconComponent={({ className }) => {
                  return state?.filters.contact_status && state.filters.contact_status !== 'active' ? (
                    <IconButton
                      color='primary'
                      onClick={() =>
                        set_filter('contact_status')({
                          target: { value: 'active' }
                        })
                      }
                    >
                      <Cancel />
                    </IconButton>
                  ) : (
                    <ArrowDropDown className={className} />
                  )
                }}
              >
                <MenuItem value={'active'}>Active</MenuItem>
                <MenuItem value={'deleted'}>Deactivated</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ flexBasis: 0, flexGrow: 1, minWidth: 200 }}>
              <InputLabel id='canvassed-status-label' size='small'>
                <b>Canvass Status</b>
              </InputLabel>
              <Select
                labelId='canvassed-status-label'
                id='canvassed-status'
                value={state.filters?.canvass ?? 'any'}
                label='Canvassed Status'
                variant='outlined'
                onChange={select_canvass}
                size='small'
                IconComponent={({ className }) => {
                  return state?.filters.canvass && state.filters.canvass !== 'any' ? (
                    <IconButton color='primary' onClick={clear_filter('canvass')}>
                      <Cancel />
                    </IconButton>
                  ) : (
                    <ArrowDropDown className={className} />
                  )
                }}
              >
                <MenuItem value={'any'}>Any</MenuItem>
                <MenuItem value={'not_canvassed'}>Not canvasssed</MenuItem>
                <MenuItem value={'canvassed'}>Canvassed (All)</MenuItem>
                <MenuItem value={'canvass_confirmed'}>Confirmed</MenuItem>
                <MenuItem value={'canvass_pending'}>Pending</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ flexBasis: 0, flexGrow: 1, minWidth: 200 }}>
              <InputLabel id='current-customers-label' size='small'>
                <b>Current Customers</b>
              </InputLabel>
              <Select
                labelId='current-customers-label'
                id='current-customers'
                value={state.filters?.current_customers ?? 'all'}
                label='Current Customers'
                variant='outlined'
                onChange={select_current_customers}
                size='small'
                IconComponent={({ className }) => {
                  return state?.filters.current_customers && state.filters.current_customers !== 'all' ? (
                    <IconButton color='primary' onClick={clear_filter('current_customers')}>
                      <Cancel />
                    </IconButton>
                  ) : (
                    <ArrowDropDown className={className} />
                  )
                }}
              >
                <MenuItem value={'all'}>All Customers</MenuItem>
                <MenuItem value={'deosan_customers'}>Current Customers</MenuItem>
                <MenuItem value={'competitor_customers'}>Competitor Customers</MenuItem>
                <MenuItem value={'targeted_customers'}>Targeted Customers</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ flexBasis: 0, flexGrow: 1, minWidth: 200 }}>
              <InputLabel id='tm-select-label' size='small'>
                <b>Territory Manager</b>
              </InputLabel>
              <Select
                labelId='tm-select-label'
                id='tm-select'
                value={state.filters?.tm ?? ''}
                label='TM'
                variant='outlined'
                onChange={select_tm}
                size='small'
                IconComponent={({ className }) => {
                  return state.filters?.tm ? (
                    <IconButton color='primary' onClick={clear_filter('tm')}>
                      <Cancel />
                    </IconButton>
                  ) : (
                    <ArrowDropDown className={className} />
                  )
                }}
              >
                <ListSubheader>North Island</ListSubheader>
                {territory_managers_grouped_by_island?.NI.sort((a, b) => (a.last_name > b.last_name ? 1 : -1)).map(
                  tm => {
                    return (
                      <MenuItem key={tm.id} value={tm.id}>
                        {[tm.first_name, tm.last_name, '-', tm.area].join(' ')}
                      </MenuItem>
                    )
                  }
                )}
                <ListSubheader>South Island</ListSubheader>
                {territory_managers_grouped_by_island?.SI.sort((a, b) => (a.last_name > b.last_name ? 1 : -1)).map(
                  tm => {
                    return (
                      <MenuItem key={tm.id} value={tm.id}>
                        {[tm.first_name, tm.last_name, '-', tm.area].join(' ')}
                      </MenuItem>
                    )
                  }
                )}
              </Select>
            </FormControl>
          </FormGroup>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0 1rem'
            }}
          >
            <ProgressBar
              sheds_count={sheds_count}
              canvassed_count={canvassed_count}
              not_canvassed_count={not_canvassed_count}
              pending_count={pending_count}
              confirmed_count={confirmed_count}
              no_business_count={no_business_count}
              canvassed_percentage={canvassed_percentage}
              not_canvassed_percentage={not_canvassed_percentage}
              pending_percentage={pending_percentage}
              confirmed_percentage={confirmed_percentage}
              no_business_percentage={no_business_percentage}
            />
          </Box>
          {data?.contacts ? (
            <>
              <TableContainer sx={{ marginTop: '1rem' }}>
                <Table>
                  {screen__gt_md ? (
                    <TableHead>
                      <TableRow>
                        {[
                          'Name',
                          'Email',
                          'Trading Name',
                          'Sheds',
                          'Retailers',
                          'TM',
                          'Phone Number',
                          'Tags',
                          'Last Canvassed'
                        ].map(h => (
                          <TableCell key={h}>{h}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  ) : null}
                  <TableBody>
                    {filtered_contacts
                      ?.slice(table_page * rows_per_page, table_page * rows_per_page + rows_per_page)
                      .map(({ id, first_name, last_name, trading_name, email_address, sheds, phone_number, tags }) => {
                        const canvass = data?.canvasses.find(
                          c => c.contact_id === id || c.shed_id === sheds.find(s => s.tm)?.shed_id
                        )
                        return (
                          <TableRow
                            key={id}
                            hover
                            onClick={() => page(`/contacts/${id}`)}
                            style={{ cursor: 'pointer' }}
                          >
                            {screen__gt_md ? (
                              <>
                                <TableCell>
                                  {first_name} {last_name}
                                </TableCell>
                                <TableCell>{email_address}</TableCell>
                                <TableCell>{trading_name}</TableCell>
                                <TableCell>
                                  {sheds.map(s => {
                                    const content = [s.dairy_company, s.dairy_number, s.region]
                                    return (
                                      <div key={content.join('+')}>
                                        {s.is_focus ? <strong>{content.join(' ')}</strong> : <>{content.join(' ')}</>}
                                      </div>
                                    )
                                  })}
                                </TableCell>
                                <TableCell>{sheds.map(s => s.retailer?.name ?? null).join(', ')}</TableCell>
                                <TableCell>
                                  {sheds.map(s => [s.tm?.first_name, s.tm?.last_name].join(' ')).join(', ')}
                                </TableCell>
                                <TableCell>{phone_number}</TableCell>
                                <TableCell>{tags.map(t => t.tag ?? null).join(', ')}</TableCell>
                                <TableCell>
                                  {canvass?.date ? DateTime.fromISO(canvass.date).toFormat('dd MMMM yyyy') : ''}
                                </TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell>
                                  {first_name || last_name ? (
                                    <>
                                      {first_name} {last_name}
                                      <br />
                                    </>
                                  ) : null}
                                  {email_address ? (
                                    <>
                                      {email_address}
                                      <br />
                                    </>
                                  ) : null}
                                  {sheds
                                    .map(s => [s.dairy_company, s.dairy_number, s.region].join(' '))
                                    .map(s => (
                                      <div key={s}>{s}</div>
                                    ))}
                                  {canvass?.date ? (
                                    <div
                                      style={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        gap: '0.25rem',
                                        background: '#1976d2',
                                        color: '#fff',
                                        padding: '0.25rem',
                                        borderRadius: '0.25rem',
                                        marginTop: '0.5rem'
                                      }}
                                    >
                                      <ContactPage sx={{ fontSize: '1rem' }} />
                                      {DateTime.fromISO(canvass.date).toFormat('dd MMMM yyyy')}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                labelRowsPerPage={screen__gt_md ? 'Rows per page' : null}
                component='div'
                count={filtered_contacts.length ?? 0}
                rowsPerPage={rows_per_page}
                page={table_page}
                onPageChange={change_table_page}
                onRowsPerPageChange={change_table_rows_per_page}
                sx={{
                  marginBottom: '10rem'
                }}
              />
            </>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: -1
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      )
    }
  ])
})
