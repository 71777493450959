import inject from 'seacreature/lib/inject'
import { useContext, useState, useEffect } from 'react'
import {
  AppBar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button
} from '@mui/material'
import {
  Cloud,
  CloudDone,
  CloudOff,
  CloudSync,
  Refresh,
  Menu
} from '@mui/icons-material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { DateTime } from 'luxon'
import page from 'page'
import theme from './theme'

const { VITE_CAN_VIEW_REPORT } = import.meta.env

inject('pod', ({ HubContext, RouterContext, StateContext }) => {
  inject('app', () => {
    const hub = useContext(HubContext)
    const Route = useContext(RouterContext)
    const NotFound = inject.one('404')
    const { online, logged_in, cloud_status, queued, last_synced_at, data } =
      useContext(StateContext)
    const [state, set_state] = useState({})
    const [mobile_open, setMobile_open] = useState(false)

    const user_id = data?.user.id
    const can_view_report = (VITE_CAN_VIEW_REPORT ?? []).includes(user_id)

    const drawer_width = 240
    const nav_items = [
      { name: 'Contacts', path: '/contacts' },
      { name: 'Sync Queue', path: '/queue' },
      ...(can_view_report ? [{ name: 'Canvass Report', path: '/report' }] : [])
    ]

    const toggle_drawer = () => {
      setMobile_open(prevState => !prevState)
    }

    const reload = () => {
      window.location.reload()
    }

    const [update_available, set_update_available] = useState(false)

    useEffect(() => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.ready.then(registration => {
          if (registration.waiting) {
            set_update_available(true)
          }
          registration.addEventListener('updatefound', () => {
            set_update_available(true)
          })
        })
      }
    }, [])

    const update_app = () => {
      navigator.serviceWorker.ready.then(registration => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        registration.waiting.addEventListener('statechange', event => {
          if (event.target.state === 'activated') {
            window.location.reload()
          }
        })
      })
    }

    useEffect(() => {
      if (queued) {
        const queue_size = Object.values(queued).reduce(
          (a, b) => a + (b ? b.length : 0),
          0
        )
        set_state({ ...state, queue_size })
      }
    }, [queued])

    return (
      <>
        <ThemeProvider theme={createTheme(theme)}>
          <AppBar
            component='nav'
            position='static'
            sx={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              zIndex: 1000,
              height: '3.5rem',
              padding: '0 0.25rem',
              position: 'sticky',
              top: 0
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
                position: 'relative'
              }}
            >
              <Button
                variant='outlined'
                onClick={toggle_drawer}
                sx={{
                  display: 'inline-flex',
                  gap: '0.5rem',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  position: 'absolute',
                  left: 0
                }}
              >
                <Menu sx={{ fontSize: '2rem' }} />
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '1rem',
                  minWidth: 50
                }}
              >
                <img
                  src='/logo.png'
                  alt='logo'
                  width='40'
                  height='40'
                  onClick={() => (logged_in ? page('/contacts') : page('/'))}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
              <Button
                variant='outlined'
                onClick={() => {
                  if (online && cloud_status !== 'sync') {
                    hub.emit('sync')
                  }
                  if (!online) {
                    page('/queue')
                  }
                }}
                sx={{
                  display: 'inline-flex',
                  gap: '0.5rem',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  position: 'absolute',
                  right: 0
                }}
              >
                {cloud_status !== 'sync' && last_synced_at ? (
                  <span
                    style={{
                      fontSize: '0.75rem'
                    }}
                  >
                    {last_synced_at.toFormat('HH:mm a')}
                  </span>
                ) : null}
                {state.queue_size ? <span>{state.queue_size}</span> : null}
                {{
                  done: <CloudDone sx={{ fontSize: '2rem' }} />,
                  offline: <CloudOff sx={{ fontSize: '2rem' }} />,
                  sync: <CloudSync sx={{ fontSize: '2rem' }} />
                }[cloud_status ?? 'offline'] ?? (
                  <Cloud sx={{ fontSize: '2rem' }} />
                )}
              </Button>
            </Box>
          </AppBar>
          {online && update_available ? (
            <Box
              sx={{
                padding: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Button
                variant='contained'
                color='update'
                onClick={update_app}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '0.5rem'
                }}
              >
                <Refresh />
                <span>App Update Available</span>
              </Button>
            </Box>
          ) : null}
          <Box component='nav'>
            <Drawer
              container={document.body}
              variant='temporary'
              open={mobile_open}
              onClose={toggle_drawer}
              ModalProps={{ keepMounted: true }}
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawer_width
                }
              }}
            >
              <Box
                onClick={toggle_drawer}
                sx={{
                  textAlign: 'center',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <div>
                  {logged_in ? (
                    <List>
                      {nav_items.map(item => (
                        <ListItem key={item.name}>
                          <ListItemButton
                            sx={{
                              textAlign: 'center',
                              color: 'primary.main'
                            }}
                            onClick={() => page(item.path)}
                          >
                            <ListItemText
                              primary={item.name}
                              primaryTypographyProps={{
                                fontSize: '1.25rem',
                                fontWeight: 'bold'
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  ) : null}
                </div>
                <div>
                  <List>
                    {logged_in ? (
                      <ListItem>
                        <ListItemButton
                          onClick={() => hub.emit('log out')}
                          sx={{
                            textAlign: 'center',
                            color: 'neutral.main'
                          }}
                        >
                          <ListItemText
                            primary='Logout'
                            primaryTypographyProps={{
                              fontSize: '1rem'
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ) : null}
                    <ListItem>
                      <ListItemButton
                        onClick={reload}
                        sx={{
                          textAlign: 'center',
                          color: 'neutral.main'
                        }}
                      >
                        <ListItemText
                          primary={
                            {
                              development: `Version ${__APP_VERSION__} - Test`,
                              production: `Version ${__APP_VERSION__}`
                            }[process.env.NODE_ENV]
                          }
                          primaryTypographyProps={{
                            fontSize: '1rem'
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </div>
              </Box>
            </Drawer>
          </Box>
          {Route ? <Route /> : <NotFound />}
        </ThemeProvider>
      </>
    )
  })
})
