import inject from 'seacreature/lib/inject'
import { createContext, useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import page from 'page'

const { VITE_BASENET_API, VITE_OAUTH2_CLIENT_ID } = import.meta.env

inject('ctx', ({ HubContext, IndexedDB }) => {
  const StateContext = createContext()

  const StateProvider = ({ children }) => {
    const hub = useContext(HubContext)
    const [state, set_state] = useState({})

    const network_status = () => {
      set_state(s => ({
        ...s,
        online: window.navigator.onLine,
        cloud_status: window.navigator.onLine ? 'online' : 'offline'
      }))
    }
    window.addEventListener('online', () => network_status())
    window.addEventListener('offline', () => network_status())
    if (state.online === undefined) network_status()

    const get_user = async access_token => {
      // console.log('Syncing user...')
      const path = `${VITE_BASENET_API}/v1/user`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID
      }
      const res = await fetch(path, { method: 'GET', headers })
      const json = await res.json()
      if (res.status === 401) {
        page('/')
        return
      }
      if (res.status !== 200) {
        console.error('Error syncing user.')
        return
      }
      await IndexedDB.set_items({ user: json.user })
      // console.log('Synced user.')
      return json.user
    }

    const get_contacts = async access_token => {
      // console.log('Syncing contacts...')
      const path = `${VITE_BASENET_API}/v1/contacts`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID
      }
      const res = await fetch(path, { method: 'GET', headers })
      const json = await res.json()
      if (res.status === 401) {
        page('/')
        return
      }
      if (res.status !== 200) {
        console.error('Error syncing contacts.')
        return
      }
      await IndexedDB.set_items({ contacts: json.contacts })
      // console.log('Synced contacts.')
      return json.contacts
    }

    const get_products = async access_token => {
      // console.log('Syncing products...')
      const path = `${VITE_BASENET_API}/v1/products`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID
      }
      const res = await fetch(path, { method: 'GET', headers })
      const json = await res.json()
      if (res.status === 401) {
        page('/')
        return
      }
      if (res.status !== 200) {
        console.error('Error syncing products.')
        return
      }
      await IndexedDB.set_items({ products: json.products })
      // console.log('Synced products.')
      return json.products
    }

    const get_retailers = async access_token => {
      // console.log('Syncing retailers...')
      const path = `${VITE_BASENET_API}/v1/retailers`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID
      }
      const res = await fetch(path, { method: 'GET', headers })
      const json = await res.json()
      if (res.status === 401) {
        page('/')
        return
      }
      if (res.status !== 200) {
        console.error('Error syncing retailers.')
        return
      }
      await IndexedDB.set_items({ retailers: json.retailers })
      // console.log('Synced retailers.')
      return json.retailers
    }

    const post_queued_canvasses = async access_token => {
      // console.log('Syncing queued canvasses...')
      const items = await IndexedDB.get_items(['queued_canvasses'])
      // console.log({ queued_canvasses: items.queued_canvasses })
      const queued_canvasses = items.queued_canvasses?.filter(c => !c.hasOwnProperty('updated_at'))
      if (!queued_canvasses) {
        // console.log('No queued canvasses to sync.')
        return
      }
      const path = `${VITE_BASENET_API}/v1/canvasses`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID,
        'content-type': 'application/json'
      }
      const body = { canvasses: queued_canvasses }
      const res = await fetch(path, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      })
      const json = await res.json()
      if (res.status !== 200 && !json.ok) {
        console.error('Error syncing queued canvasses.')
        return
      }
      if (res.status === 401) {
        page('/')
        return
      }
      await IndexedDB.set_items({
        queued_canvasses: items.queued_canvasses.filter(c => !queued_canvasses.find(qc => qc.id === c.id))
      })
      // console.log('Synced queued canvasses.')
    }

    const put_queued_canvasses = async access_token => {
      // console.log('Syncing queued canvasses...')
      const items = await IndexedDB.get_items(['queued_canvasses'])
      const queued_canvasses = items.queued_canvasses?.filter(c => c.hasOwnProperty('updated_at'))
      if (!queued_canvasses) {
        // console.log('No queued canvasses to sync.')
        return
      }
      const path = `${VITE_BASENET_API}/v1/canvasses`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID,
        'content-type': 'application/json'
      }
      const body = { canvasses: queued_canvasses }
      const res = await fetch(path, {
        method: 'PUT',
        headers,
        body: JSON.stringify(body)
      })
      const json = await res.json()
      if (res.status !== 200 && !json.ok) {
        console.error('Error syncing queued canvasses.')
        return
      }
      if (res.status === 401) {
        page('/')
        return
      }
      await IndexedDB.set_items({
        queued_canvasses: items.queued_canvasses.filter(c => !queued_canvasses.find(qc => qc.id === c.id))
      })
      // console.log('Synced queued canvasses.')
    }

    const get_territory_managers = async access_token => {
      // console.log('Syncing tm users...')
      const path = `${VITE_BASENET_API}/v1/users/tm`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID
      }
      const res = await fetch(path, { method: 'GET', headers })
      const json = await res.json()
      if (res.status === 401) {
        page('/')
        return
      }
      if (res.status !== 200) {
        console.error('Error syncing tm users.')
        return
      }
      await IndexedDB.set_items({ territory_managers: json.territory_managers })
      // console.log('Synced tm users.')
      return json.territory_managers
    }

    const update_queued_contacts = async access_token => {
      // console.log('Syncing queued contacts...')
      const { queued_contacts } = await IndexedDB.get_items(['queued_contacts'])
      if (!queued_contacts) {
        // console.log('No queued contacts to sync.')
        return
      }
      const path = `${VITE_BASENET_API}/v1/contacts`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID,
        'content-type': 'application/json'
      }
      const body = { contacts: queued_contacts }
      const res = await fetch(path, {
        method: 'PUT',
        headers,
        body: JSON.stringify(body)
      })
      const json = await res.json()
      if (res.status !== 200 && !json.ok) {
        console.error('Error syncing queued contacts.')
        return
      }
      if (res.status === 401) {
        page('/')
        return
      }
      IndexedDB.delete_items(['queued_contacts'])
      // console.log('Synced queued contacts.')
    }

    const delete_secondary_contact = async (access_token, { canvass_id, contact_id }) => {
      const path = `${VITE_BASENET_API}/v1/canvasses/${canvass_id}/contacts/${contact_id}`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID,
        'content-type': 'application/json'
      }
      const res = await fetch(path, {
        method: 'DELETE',
        headers
      })
      const json = await res.json()
      return await get_contacts(access_token)
    }

    const delete_primary_contact = async (access_token, { canvass_id, contact_id_to_promote }) => {
      const path = `${VITE_BASENET_API}/v1/canvasses/${canvass_id}/contacts/${contact_id_to_promote}/promote`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID,
        'content-type': 'application/json'
      }
      const body = { replace: false }
      const res = await fetch(path, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      })
      const json = await res.json()
      await get_contacts(access_token)
      return page(`/contacts/${json.contact_id}/canvasses/${json.canvass_id}`)
    }

    const promote_to_primary_contact = async (access_token, { canvass_id, contact_id }) => {
      const path = `${VITE_BASENET_API}/v1/canvasses/${canvass_id}/contacts/${contact_id}/promote`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID,
        'content-type': 'application/json'
      }
      const body = { replace: true }
      const res = await fetch(path, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      })
      const json = await res.json()
      await get_contacts(access_token)
      return page(`/contacts/${json.contact_id}/canvasses/${json.canvass_id}`)
    }

    const set_contact_details = async (
      access_token,
      { contact_id, first_name, last_name, trading_name, email_address, phone_number }
    ) => {
      const path = `${VITE_BASENET_API}/v1/contacts/${contact_id}`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID,
        'content-type': 'application/json'
      }
      const body = { first_name, last_name, trading_name, email_address, phone_number }
      const res = await fetch(path, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      })
      const json = await res.json()
      return await get_contacts(access_token)
    }

    const add_contact = async (access_token, { canvass_id, contact_id }) => {
      const path = `${VITE_BASENET_API}/v1/canvasses/${canvass_id}/contacts/${contact_id}`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID,
        'content-type': 'application/json'
      }
      const res = await fetch(path, {
        method: 'POST',
        headers
      })
      const json = await res.json()
      await get_contacts(access_token)
      return page(`/contacts/${json.contact_id}/canvasses/${json.canvass_id}`)
    }

    const set_canvass_contact_retailer = async (
      access_token,
      { contact_id, canvass_id, retailer_id, previous_retailer_id }
    ) => {
      const path = `${VITE_BASENET_API}/v1/canvasses/${canvass_id}/contacts/${contact_id}/retailer`
      const headers = {
        'x-access-token': access_token,
        'x-client-id': VITE_OAUTH2_CLIENT_ID,
        'content-type': 'application/json'
      }
      const body = { retailer_id, previous_retailer_id }
      const res = await fetch(path, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      })
      const json = await res.json()
      return await get_contacts(access_token)
    }

    useEffect(
      hub.effect(hub => {
        hub.on('log in', async ({ username, password }) => {
          const path = `${VITE_BASENET_API}/login`
          const headers = {
            'Content-Type': 'application/json'
          }
          const body = { username, password, client_id: VITE_OAUTH2_CLIENT_ID }
          const res = await fetch(path, {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
          })
          const j = await res.json()
          if (res.status !== 200) {
            console.error('Error logging in.')
            set_state(s => ({ ...s, has_log_in_error: true }))
            return
          }
          await IndexedDB.set_items({
            auth: {
              access_token: j.access_token,
              refresh_token: j.refresh_token,
              created_at: Date.now()
            }
          })
          set_state(s => ({ ...s, logged_in: true }))
          hub.emit('sync')
          page('/contacts')
        })

        hub.on('log out', async () => {
          await IndexedDB.clear()
          set_state(s => ({
            ...s,
            logged_in: false,
            last_synced_at: null,
            cloud_status: state.online ? 'online' : 'offline'
          }))
          page('/')
        })

        hub.on('sync', async () => {
          console.log('SYNCING')
          const { auth } = (await IndexedDB.get_items(['auth'])) ?? {}
          if (!state.online) {
            console.log('Offline. Using local data.')
            set_state(s => ({ ...s, cloud_status: 'offline' }))
            if (!auth?.access_token) {
              console.log('Invalid access token. Log in again.')
              set_state(s => ({ ...s, logged_in: false }))
              page('/')
              return
            }
            set_state(s => ({ ...s, logged_in: true }))
            const { contacts, products, territory_managers, retailers } = await IndexedDB.get_items([
              'user',
              'contacts',
              'products',
              'territory_managers',
              'retailers'
            ])
            const canvasses = contacts.reduce((acc, c) => [...acc, ...c.canvasses], [])
            set_state(s => ({
              ...s,
              data: {
                user,
                contacts,
                products,
                territory_managers,
                retailers,
                canvasses
              }
            }))
            return
          }
          if (!auth?.access_token || Date.now() - auth.created_at > 3600 * 1000) {
            console.log('Invalid access token. Log in again.')
            set_state(s => ({ ...s, logged_in: false }))
            page('/')
            return
          }
          set_state(s => ({ ...s, logged_in: true }))
          console.log('Syncing...')
          set_state(s => ({ ...s, cloud_status: 'sync' }))
          await post_queued_canvasses(auth.access_token)
          await put_queued_canvasses(auth.access_token)
          await update_queued_contacts(auth.access_token)
          await Promise.all([
            get_user(auth.access_token),
            get_contacts(auth.access_token),
            get_products(auth.access_token),
            get_territory_managers(auth.access_token),
            get_retailers(auth.access_token)
          ]).then(([user, contacts, products, territory_managers, retailers]) => {
            const canvasses = contacts.reduce((acc, c) => [...acc, ...c.canvasses], [])
            set_state(s => ({
              ...s,
              data: {
                user,
                contacts,
                products,
                territory_managers,
                canvasses,
                retailers
              }
            }))
          })
          console.log('Synced.')
          set_state(s => ({ ...s, cloud_status: 'done', last_synced_at: DateTime.now() }))
        })

        hub.on('queue', async ({ collection, id, fields }) => {
          await IndexedDB.upsert_item_in_array(`queued_${collection}`, {
            id,
            ...fields,
            updated_at: Date.now(),
            updated: Date.now()
          })
        })

        hub.on('delete secondary contact', async ({ canvass_id, contact_id }) => {
          const { auth } = (await IndexedDB.get_items(['auth'])) ?? {}
          await delete_secondary_contact(auth.access_token, { canvass_id, contact_id })
          location.reload()
        })

        hub.on('delete primary contact', async ({ canvass_id, contact_id_to_delete, contact_id_to_promote }) => {
          const { auth } = (await IndexedDB.get_items(['auth'])) ?? {}
          await delete_primary_contact(auth.access_token, { canvass_id, contact_id_to_promote })
          location.reload()
        })

        hub.on('promote to primary contact', async ({ canvass_id, contact_id }) => {
          const { auth } = (await IndexedDB.get_items(['auth'])) ?? {}
          await promote_to_primary_contact(auth.access_token, { canvass_id, contact_id })
          location.reload()
        })

        hub.on(
          'set contact details',
          async ({ contact_id, first_name, last_name, trading_name, email_address, phone_number }) => {
            const { auth } = (await IndexedDB.get_items(['auth'])) ?? {}
            await set_contact_details(auth.access_token, {
              contact_id,
              first_name,
              last_name,
              trading_name,
              email_address,
              phone_number
            })
            location.reload()
          }
        )

        hub.on(
          'set canvass contact retailer',
          async ({ contact_id, canvass_id, retailer_id, previous_retailer_id }) => {
            const { auth } = (await IndexedDB.get_items(['auth'])) ?? {}
            await set_canvass_contact_retailer(auth.access_token, {
              contact_id,
              canvass_id,
              retailer_id,
              previous_retailer_id
            })
          }
        )

        hub.on('add contact', async ({ canvass_id, contact_id }) => {
          const { auth } = (await IndexedDB.get_items(['auth'])) ?? {}
          await add_contact(auth.access_token, { canvass_id, contact_id })
        })
      }),
      [state]
    )

    useEffect(
      hub.effect(hub => {
        hub.emit('sync')
      }),
      [state.online]
    )

    useEffect(() => {
      if (state.logged_in !== undefined && !state.logged_in && location.pathname != '/') page('/')
    }, [state.logged_in])

    useEffect(() => {
      ;(async () => {
        const { queued_contacts, queued_canvasses } = await IndexedDB.get_items(['queued_contacts', 'queued_canvasses'])
        set_state(s => ({
          ...s,
          queued: {
            ...s.queued,
            contacts: queued_contacts,
            canvasses: queued_canvasses
          }
        }))
      })()
    }, [state.data])

    return <StateContext.Provider value={state} children={children} />
  }

  inject('provider', StateProvider)

  return { StateContext, StateProvider }
})
