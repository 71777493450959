import * as React from 'react'
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'

const ConfirmSubmitCanvas = ({ open, on_close, products }) => {
  const has_non_confirmed_products = products.some(({ agreement_status }) => agreement_status != 'Confirmed')

  const options = {
    false: [
      {
        text: 'Submit',
        on_click: e => on_close([]),
        key: 'submit'
      },
      {
        text: 'Cancel',
        on_click: e => on_close([], true),
        key: 'cancel'
      }
    ],
    true: [
      {
        text: 'Confirm all Products and Submit',
        on_click: e => on_close(products.map(product => product.product_id)),
        key: 'submit_after_confirmed'
      },
      {
        text: 'Submit without Confirming all Products',
        on_click: e => on_close([]),
        key: 'submit_without_confirming'
      },
      { text: 'Cancel', on_click: e => on_close([], true), key: 'cancel_without_confirming' }
    ]
  }

  return (
    <Dialog open={open} onClose={on_close}>
      <DialogTitle>Are you Sure?</DialogTitle>
      {has_non_confirmed_products && <DialogTitle>There are Non-Confirmed Products in This Canvas</DialogTitle>}
      <DialogContent>
        {options[has_non_confirmed_products].map(({ text, on_click, key }) => {
          return (
            <p key={key}>
              <Button variant='contained' onClick={on_click}>
                {text}
              </Button>
            </p>
          )
        })}
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmSubmitCanvas
