import inject from 'seacreature/lib/inject'

inject('ctx', async () => {
  const { VITE_INDEXEDDB_NAME, VITE_INDEXEDDB_VERSION, VITE_INDEXEDDB_STORE_NAME } = import.meta.env

  const IndexedDB = {
    db: null,
    get_db: () => {
      return IndexedDB.db
    },
    open: () => {
      return new Promise((resolve, reject) => {
        const request = window.indexedDB.open(VITE_INDEXEDDB_NAME, VITE_INDEXEDDB_VERSION)

        request.onerror = e => {
          reject(e.target.errorCode)
        }

        request.onsuccess = e => {
          IndexedDB.db = e.target.result
          resolve(IndexedDB.db)
        }

        request.onupgradeneeded = e => {
          const db = e.target.result
          db.createObjectStore(VITE_INDEXEDDB_STORE_NAME, { keyPath: 'id' })
        }
      })
    },
    get: key => {
      return new Promise((resolve, reject) => {
        const transaction = IndexedDB.db.transaction([VITE_INDEXEDDB_STORE_NAME], 'readonly')
        const objectStore = transaction.objectStore(VITE_INDEXEDDB_STORE_NAME)
        const request = objectStore.get(key)

        request.onerror = e => {
          reject(e.target.errorCode)
        }

        request.onsuccess = e => {
          resolve(request.result)
        }
      })
    },
    get_items: async keys => {
      const obj = {}
      for (const key of keys) {
        const { value } = (await IndexedDB.get(key)) ?? {}
        obj[key] = value
      }
      return obj
    },
    set: (key, value) => {
      return new Promise((resolve, reject) => {
        const transaction = IndexedDB.db.transaction([VITE_INDEXEDDB_STORE_NAME], 'readwrite')
        const objectStore = transaction.objectStore(VITE_INDEXEDDB_STORE_NAME)
        const request = objectStore.put({ id: key, value })

        request.onerror = e => {
          reject(e.target.errorCode)
        }

        request.onsuccess = e => {
          resolve(request.result)
        }
      })
    },
    set_items: async obj => {
      for (const key in obj) {
        await IndexedDB.set(key, obj[key])
      }
      return
    },
    update_item_in_array: async (key, item) => {
      const { value } = (await IndexedDB.get(key)) ?? {}
      const index = value.findIndex(v => v.id === item.id)
      value[index] = { ...value[index], ...item }
      await IndexedDB.set(key, value)
      return
    },
    add_item_to_array: async (key, item) => {
      const { value = [] } = (await IndexedDB.get(key)) ?? {}
      await IndexedDB.set(key, [...value, item])
      return
    },
    upsert_item_in_array: async (key, item) => {
      const { value = [] } = (await IndexedDB.get(key)) ?? {}
      const index = value.findIndex(v => v.id === item.id)
      if (index === -1) {
        await IndexedDB.set(key, [...value, item])
      } else {
        value[index] = { ...value[index], ...item }
        await IndexedDB.set(key, value)
      }
      return
    },
    delete: key => {
      return new Promise((resolve, reject) => {
        const transaction = IndexedDB.db.transaction([VITE_INDEXEDDB_STORE_NAME], 'readwrite')
        const objectStore = transaction.objectStore(VITE_INDEXEDDB_STORE_NAME)
        const request = objectStore.delete(key)

        request.onerror = e => {
          reject(e.target.errorCode)
        }

        request.onsuccess = e => {
          resolve(request.result)
        }
      })
    },
    delete_items: async keys => {
      for (const key of keys) {
        await IndexedDB.delete(key)
      }
      return
    },
    clear: () => {
      return new Promise((resolve, reject) => {
        const transaction = IndexedDB.db.transaction([VITE_INDEXEDDB_STORE_NAME], 'readwrite')
        const objectStore = transaction.objectStore(VITE_INDEXEDDB_STORE_NAME)
        const request = objectStore.clear()

        request.onerror = e => {
          reject(e.target.errorCode)
        }

        request.onsuccess = e => {
          resolve(request.result)
        }
      })
    },
    keys: () => {
      return new Promise((resolve, reject) => {
        const transaction = IndexedDB.db.transaction([VITE_INDEXEDDB_STORE_NAME], 'readonly')
        const objectStore = transaction.objectStore(VITE_INDEXEDDB_STORE_NAME)
        const request = objectStore.getAllKeys()

        request.onerror = e => {
          reject(e.target.errorCode)
        }

        request.onsuccess = e => {
          resolve(request.result)
        }
      })
    },
    values: () => {
      return new Promise((resolve, reject) => {
        const transaction = IndexedDB.db.transaction([VITE_INDEXEDDB_STORE_NAME], 'readonly')
        const objectStore = transaction.objectStore(VITE_INDEXEDDB_STORE_NAME)
        const request = objectStore.getAll()

        request.onerror = e => {
          reject(e.target.errorCode)
        }

        request.onsuccess = e => {
          resolve(request.result)
        }
      })
    },
    entries: () => {
      return new Promise((resolve, reject) => {
        const transaction = IndexedDB.db.transaction([VITE_INDEXEDDB_STORE_NAME], 'readonly')
        const objectStore = transaction.objectStore(VITE_INDEXEDDB_STORE_NAME)
        const request = objectStore.getAll()

        request.onerror = e => {
          reject(e.target.errorCode)
        }

        request.onsuccess = e => {
          resolve(request.result.map(({ id, value }) => [id, value]))
        }
      })
    },
    count: () => {
      return new Promise((resolve, reject) => {
        const transaction = IndexedDB.db.transaction([VITE_INDEXEDDB_STORE_NAME], 'readonly')
        const objectStore = transaction.objectStore(VITE_INDEXEDDB_STORE_NAME)
        const request = objectStore.count()

        request.onerror = e => {
          reject(e.target.errorCode)
        }

        request.onsuccess = e => {
          resolve(request.result)
        }
      })
    },
    close: () => {
      IndexedDB.db.close()
    },
    connect: async () => {
      await IndexedDB.open()
    }
  }

  await IndexedDB.connect()

  return { IndexedDB }
})
