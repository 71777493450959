import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState, useMemo } from 'react'
import page from 'page'
import {
  Box,
  Fab,
  CircularProgress,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormGroup,
  IconButton
} from '@mui/material'
import { Cancel, ArrowDropDown, ContactPage } from '@mui/icons-material'
import { DateTime } from 'luxon'
import papaparse from 'papaparse'

const { VITE_CAN_VIEW_REPORT } = import.meta.env
const NUM_YEARS = 10

inject('pod', ({ StateContext }) => {
  inject('route', [
    '/report',
    p => () => {
      const { data } = useContext(StateContext)

      const [year, set_year_fn] = useState(parseInt(localStorage.getItem('filter.year')) ?? DateTime.now().year)
      const [current_customers, set_current_customers_fn] = useState(
        localStorage.getItem('filter.current_customers') ?? 'all'
      )

      const set_year = value => {
        localStorage.setItem('filter.year', value)
        set_year_fn(value)
      }

      const set_current_customers = value => {
        localStorage.setItem('filter.current_customers', value)
        set_current_customers_fn(value)
      }

      useEffect(() => {
        const user_id = data?.user.id
        if (user_id) {
          const can_view_report = (VITE_CAN_VIEW_REPORT ?? []).includes(user_id)
          if (!can_view_report) page.redirect('/')
        }
      }, [data])

      const territory_managers = data?.territory_managers
      const contacts = data?.contacts.filter(c => {
        // if (!c.sheds.some(s => s.tm)) return false
        // return c.deosan_products
        return c.sheds.some(s => s.tm)
      })
      const canvasses = useMemo(
        () =>
          data?.canvasses.reduce((acc, c) => {
            if (!acc.some(a => a.shed_id === c.shed_id)) acc.push(c)
            return acc
          }, []) ?? [],
        [data?.canvasses, contacts]
      )

      const which_island = area_name => {
        if (!area_name) return null
        if (area_name.toLowerCase().includes('north island')) return 'NI'
        if (area_name.toLowerCase().includes('south island')) return 'SI'
        const island = area_name.split(' ')[0]
        if (island.toLowerCase().includes('training')) return 'NI'
        return island
      }

      const territory_managers_by_island_data =
        territory_managers?.reduce(
          (acc, tm) => {
            if (!tm.area_name) return acc
            const island = which_island(tm.area_name)
            if (!island) return acc
            const area_split = tm.area_name.replace(/\d+/g, '').split('-')
            const area = tm.area_name.replace(/\d+/g, '').split('-')[area_split.length - 1].trim()
            const first_name = tm.first_name.replace(/\d+\s-\s/, '')
            const last_name = tm.last_name.replace(/\d+\s-\s/, '')
            const tm_contacts = contacts
              .filter(c => c.sheds.some(s => s.tm?.id == tm.id))
              .filter(c => {
                if (current_customers === 'deosan_customers') return c.deosan_products
                if (current_customers === 'competitor_customers') return !c.deosan_products
                if (current_customers === 'targeted_customers') return c.sheds.some(s => s.is_focus)
                return true
              })

            const tm_sheds = tm_contacts.reduce((acc, c) => {
              c.sheds.forEach(s => {
                if (!acc.some(a => a.shed_id === s.shed_id)) acc.push(s)
              })
              return acc
            }, [])
            // .filter(s => (current_customers === 'targeted_customers' ? s.is_focus : true))
            const sheds_count = tm_sheds.length
            const tm_canvasses = canvasses
              .filter(c => tm_sheds.some(s => s.shed_id === c.shed_id))
              .filter(({ created_at }) => year === DateTime.fromISO(created_at).year)
            const canvassed_count = tm_canvasses.length
            const not_canvassed_count = sheds_count - canvassed_count
            const pending_count = tm_canvasses.filter(c => c.agreement_status === 'Pending').length
            const confirmed_count = tm_canvasses.filter(c => c.agreement_status === 'Confirmed').length
            const no_business_count = tm_canvasses.filter(c => c.agreement_status === 'No Business').length
            const canvassed_percentage = Math.round(canvassed_count ? (canvassed_count / sheds_count) * 100 : 0)
            const not_canvassed_percentage = Math.round(
              not_canvassed_count ? (not_canvassed_count / sheds_count) * 100 : 0
            )
            const pending_percentage = pending_count ? (pending_count / canvassed_count) * 100 : 0
            const confirmed_percentage = confirmed_count ? (confirmed_count / canvassed_count) * 100 : 0
            const no_business_percentage = no_business_count ? (no_business_count / canvassed_count) * 100 : 0
            acc[island].push({
              id: tm.id,
              island: island,
              area: area,
              first_name: first_name,
              last_name: last_name,
              sheds_count: sheds_count,
              canvassed_count: canvassed_count,
              not_canvassed_count: not_canvassed_count,
              pending_count: pending_count,
              confirmed_count: confirmed_count,
              no_business_count: no_business_count,
              canvassed_percentage: canvassed_percentage,
              not_canvassed_percentage: not_canvassed_percentage,
              pending_percentage: pending_percentage,
              confirmed_percentage: confirmed_percentage,
              no_business_percentage: no_business_percentage
            })
            return acc
          },
          { NI: [], SI: [] }
        ) ?? []

      const island_data = Object.keys(territory_managers_by_island_data).map(island => {
        const island_data = territory_managers_by_island_data[island]
        const island_sheds_count = island_data.reduce((acc, tm) => acc + tm.sheds_count, 0)
        const island_canvassed_count = island_data.reduce((acc, tm) => acc + tm.canvassed_count, 0)
        const island_not_canvassed_count = island_data.reduce((acc, tm) => acc + tm.not_canvassed_count, 0)
        const island_pending_count = island_data.reduce((acc, tm) => acc + tm.pending_count, 0)
        const island_confirmed_count = island_data.reduce((acc, tm) => acc + tm.confirmed_count, 0)
        const island_no_business_count = island_data.reduce((acc, tm) => acc + tm.no_business_count, 0)
        const island_canvassed_percentage = Math.round(
          island_canvassed_count ? (island_canvassed_count / island_sheds_count) * 100 : 0
        )
        const island_not_canvassed_percentage = Math.round(
          island_not_canvassed_count ? (island_not_canvassed_count / island_sheds_count) * 100 : 0
        )
        const island_pending_percentage = island_pending_count
          ? (island_pending_count / island_canvassed_count) * 100
          : 0
        const island_confirmed_percentage = island_confirmed_count
          ? (island_confirmed_count / island_canvassed_count) * 100
          : 0
        const island_no_business_percentage = island_no_business_count
          ? (island_no_business_count / island_canvassed_count) * 100
          : 0
        return {
          island: island,
          sheds_count: island_sheds_count,
          canvassed_count: island_canvassed_count,
          not_canvassed_count: island_not_canvassed_count,
          pending_count: island_pending_count,
          confirmed_count: island_confirmed_count,
          no_business_count: island_no_business_count,
          canvassed_percentage: island_canvassed_percentage,
          not_canvassed_percentage: island_not_canvassed_percentage,
          pending_percentage: island_pending_percentage,
          confirmed_percentage: island_confirmed_percentage,
          no_business_percentage: island_no_business_percentage,
          territory_managers: island_data
        }
      })

      const ProgressBar = ({
        heading,
        subheading,
        sheds_count,
        canvassed_count,
        not_canvassed_count,
        pending_count,
        confirmed_count,
        no_business_count,
        canvassed_percentage,
        not_canvassed_percentage,
        pending_percentage,
        confirmed_percentage,
        no_business_percentage,
        scale = 1
      }) => {
        return (
          <Box>
            {heading ? (
              <h3
                style={{
                  margin: '2rem 0 0.5rem 0',
                  fontSize: `${scale * 1.5}rem`
                }}
              >
                {heading}
              </h3>
            ) : null}
            <span
              style={{
                fontSize: `${scale * 1}rem`
              }}
            >
              {subheading}
            </span>
            {sheds_count > 0 ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '1rem 0',
                    height: `${scale * 2}rem`
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: `${canvassed_percentage}%`
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: 'highlight.blue',
                        width: `${pending_percentage}%`
                      }}
                    />
                    <Box
                      sx={{
                        backgroundColor: 'highlight.green',
                        width: `${confirmed_percentage}%`
                      }}
                    />
                    <Box
                      sx={{
                        backgroundColor: 'highlight.yellow',
                        width: `${no_business_percentage}%`
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: 'highlight.silver',
                      width: `${not_canvassed_percentage}%`
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem'
                    }}
                  >
                    <Box
                      sx={{
                        width: '0.75rem',
                        height: '0.75rem',
                        backgroundColor: 'highlight.blue'
                      }}
                    />
                    <span style={{ fontSize: '0.75rem' }}>
                      <span style={{ fontWeight: 'bold' }}>{pending_count}</span> Pending
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem'
                    }}
                  >
                    <Box
                      sx={{
                        width: '0.75rem',
                        height: '0.75rem',
                        backgroundColor: 'highlight.green'
                      }}
                    />
                    <span style={{ fontSize: '0.75rem' }}>
                      <span style={{ fontWeight: 'bold' }}>{confirmed_count}</span> Confirmed
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem'
                    }}
                  >
                    <Box
                      sx={{
                        width: '0.75rem',
                        height: '0.75rem',
                        backgroundColor: 'highlight.yellow'
                      }}
                    />
                    <span style={{ fontSize: '0.75rem' }}>
                      <span style={{ fontWeight: 'bold' }}>{no_business_count}</span> No Business
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem'
                    }}
                  >
                    <Box
                      sx={{
                        width: '0.75rem',
                        height: '0.75rem',
                        backgroundColor: 'highlight.silver'
                      }}
                    />
                    <span style={{ fontSize: '0.75rem' }}>
                      <span style={{ fontWeight: 'bold' }}>{not_canvassed_count}</span> Not Canvassed
                    </span>
                  </Box>
                </Box>
              </>
            ) : null}
          </Box>
        )
      }

      const format_price = price => {
        if (!price) return
        return Number(price)
          .toLocaleString('en-US', {
            style: 'currency',
            currency: 'NZD'
          })
          .replace('NZ$', '$')
      }

      const generate_report = () => {
        const report_data = data.canvasses
          .filter(({ created_at }) => year === DateTime.fromISO(created_at).year)
          .reduce((acc, c, i) => {
            if (c.user_id === 61) return acc
            const tm = data.territory_managers.find(tm => tm.id == c.user_id)
            if (!tm) return acc
            const contact = data.contacts.find(contact => contact.id == c.contact_id)

            if (current_customers === 'deosan_customers' && contact.deosan_products) return acc
            if (current_customers === 'competitor_customers' && !contact.deosan_products) return acc
            if (current_customers === 'targeted_customers' && !contact.sheds.some(s => s.is_focus)) return acc

            const shed = contact.sheds.find(shed => shed.shed_id == c.shed_id)

            if (current_customers === 'targeted_customers' && !shed?.is_focus) return acc

            const retailer = data.retailers.find(retailer => retailer.id == shed?.retailer_id)
            for (const p of c.products) {
              const product = data.products.find(product => product.id == p.product_id)
              acc.push({
                area: shed?.region,
                dairy_number: shed?.dairy_number,
                trading_name: contact.trading_name,
                contact: [contact.first_name, contact.last_name].join(' '),
                retailer: retailer?.name,
                herd_size: shed?.herd_size,
                product: p.name,
                quantity: p.quantity,
                month: DateTime.fromFormat(p.delivery_date, 'dd-MM-yyyy').toFormat('MMMM'),
                year: DateTime.fromFormat(p.delivery_date, 'dd-MM-yyyy').toFormat('yyyy'),
                unit_price: format_price(product.price),
                total_price: format_price(p.agreed_price ?? p.price),
                notes: c.note_farm,
                tm: [tm?.first_name.replace(/\d+\s-\s/, ''), tm?.last_name.replace(/\d+\s-\s/, '')].join(' '),
                pack_size: p.pack_size,
                pack_size_unit: p.pack_size_unit,
                status: c.agreement_status
              })
            }
            return acc
          }, [])
        const csv = papaparse.unparse(report_data)
        const csv_data = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        const csv_report_date = DateTime.local().toFormat('dd-MM-yyyy')
        const csv_file_name = `deosan-canvass-report-${csv_report_date}.csv`
        let csv_url = null
        if (navigator.msSaveBlob) {
          csv_url = navigator.msSaveBlob(csv_data, csv_file_name)
        } else {
          csv_url = window.URL.createObjectURL(csv_data)
        }
        let link = document.createElement('a')
        link.href = csv_url
        link.setAttribute('download', csv_file_name)
        link.click()
      }

      const filters = (
        <FormGroup row sx={{ gap: '1rem', margin: '1rem', flexWrap: 'wrap' }}>
          <FormControl sx={{ flexBasis: 0, flexGrow: 0, minWidth: 200 }}>
            <InputLabel id='report-year-label' size='small'>
              <b>Report Year</b>
            </InputLabel>
            <Select
              labelId='report-year-label'
              id='report-year'
              value={year}
              label='Report Year'
              variant='outlined'
              onChange={e => set_year(e.target.value)}
              size='small'
            >
              {Array(NUM_YEARS)
                .fill(DateTime.now().year)
                .map((x, i) => x - i)
                .map(y => (
                  <MenuItem key={`year_${y}`} value={y}>
                    {y}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl sx={{ flexBasis: 0, flexGrow: 0, minWidth: 200 }}>
            <InputLabel id='current-customers-label' size='small'>
              <b>Current Customers</b>
            </InputLabel>
            <Select
              labelId='current-customers-label'
              id='current-customers'
              value={current_customers}
              label='Current Customers'
              variant='outlined'
              onChange={e => set_current_customers(e.target.value)}
              size='small'
              IconComponent={({ className }) => {
                return current_customers !== 'all' ? (
                  <IconButton color='primary' onClick={e => set_current_customers('all')}>
                    <Cancel />
                  </IconButton>
                ) : (
                  <ArrowDropDown className={className} />
                )
              }}
            >
              <MenuItem value={'all'}>All Customers</MenuItem>
              <MenuItem value={'deosan_customers'}>Current Customers</MenuItem>
              <MenuItem value={'competitor_customers'}>Competitor Customers</MenuItem>
              <MenuItem value={'targeted_customers'}>Targeted Customers</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>
      )

      return (
        <>
          <Fab
            variant='extended'
            color='primary'
            onClick={() => page('/canvass/create')}
            sx={{
              position: 'fixed',
              bottom: '2rem',
              right: '2rem',
              zIndex: 1000
            }}
          >
            <ContactPage sx={{ mr: 1 }} />
            New Canvass
          </Fab>
          {data ? (
            <>
              <Box sx={{ padding: '1rem' }}>
                <Button onClick={generate_report} variant='contained'>
                  Generate Report
                </Button>
              </Box>
              {filters}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '1rem',
                  marginBottom: '10rem'
                }}
              >
                {island_data.map(data => {
                  const {
                    island,
                    sheds_count,
                    canvassed_count,
                    not_canvassed_count,
                    pending_count,
                    confirmed_count,
                    no_business_count,
                    canvassed_percentage,
                    not_canvassed_percentage,
                    pending_percentage,
                    confirmed_percentage,
                    no_business_percentage,
                    territory_managers
                  } = data

                  return (
                    <Box key={island}>
                      <ProgressBar
                        key={island}
                        heading={{ NI: 'North Island', SI: 'South Island' }[island]}
                        subheading={`${sheds_count > 0 ? sheds_count : 'No'} ${
                          sheds_count === 1 ? 'contact' : 'contacts'
                        }`}
                        sheds_count={sheds_count}
                        canvassed_count={canvassed_count}
                        not_canvassed_count={not_canvassed_count}
                        pending_count={pending_count}
                        confirmed_count={confirmed_count}
                        no_business_count={no_business_count}
                        canvassed_percentage={canvassed_percentage}
                        not_canvassed_percentage={not_canvassed_percentage}
                        pending_percentage={pending_percentage}
                        confirmed_percentage={confirmed_percentage}
                        no_business_percentage={no_business_percentage}
                        scale={1.75}
                      />
                      {territory_managers
                        .sort((a, b) => (a.last_name > b.last_name ? 1 : -1))
                        .map(data => {
                          const {
                            id,
                            island,
                            area,
                            first_name,
                            last_name,
                            sheds_count,
                            canvassed_count,
                            not_canvassed_count,
                            pending_count,
                            confirmed_count,
                            no_business_count,
                            canvassed_percentage,
                            not_canvassed_percentage,
                            pending_percentage,
                            confirmed_percentage,
                            no_business_percentage
                          } = data

                          return (
                            <ProgressBar
                              key={id}
                              heading={`${first_name} ${last_name} (${area})`}
                              subheading={`${sheds_count > 0 ? sheds_count : 'No'} ${
                                sheds_count === 1 ? 'contact' : 'contacts'
                              }`}
                              sheds_count={sheds_count}
                              canvassed_count={canvassed_count}
                              not_canvassed_count={not_canvassed_count}
                              pending_count={pending_count}
                              confirmed_count={confirmed_count}
                              no_business_count={no_business_count}
                              canvassed_percentage={canvassed_percentage}
                              not_canvassed_percentage={not_canvassed_percentage}
                              pending_percentage={pending_percentage}
                              confirmed_percentage={confirmed_percentage}
                              no_business_percentage={no_business_percentage}
                            />
                          )
                        })}
                    </Box>
                  )
                })}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: -1
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      )
    }
  ])
})
