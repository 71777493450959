import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState } from 'react'
import page from 'page'
import { Breadcrumbs, Button, Box, Grid, Link, CircularProgress, Fab, FormGroup } from '@mui/material'
import { NavigateNext, ContactPage } from '@mui/icons-material'
import { DateTime } from 'luxon'

inject('pod', ({ HubContext, StateContext }) => {
  inject('route', [
    '/contacts/:contact_id',
    p => () => {
      const { contact_id } = p.params
      const { data } = useContext(StateContext)
      const [state, set_state] = useState({})

      useEffect(() => {
        const contact = data?.contacts?.find(c => c.id == contact_id)
        if (data?.contacts && !contact) return page('/contacts')
        if (contact) {
          contact.name = [contact.first_name, contact.last_name].join(' ').trim()
          set_state(() => ({ contact }))
        }
      }, [data])

      const sentry_test = () => e => {
        e.preventDefault()
        throw new Error('Sentry Test')
      }

      const select_canvass = c => {
        const canvass_date = DateTime.fromISO(c.date)
        if (canvass_date.year === 2023) {
          page(`/contacts/${contact_id}/canvasses/${c.id}/view`)
        } else {
          page(`/contacts/${contact_id}/canvasses/${c.id}`)
        }
      }

      const sheds =
        state.contact?.sheds.reduce((acc, s) => {
          const shed = {
            shed_id: s.shed_id,
            address: s.address,
            dairy_company: s.dairy_company,
            dairy_number: s.dairy_number,
            region: s.region,
            shed_products: s.shed_products,
            tags: s.tags,
            is_focus: s.is_focus
          }
          acc.push(shed)
          return acc
        }, []) ?? []

      const shed_fragment = s => (
        <Box
          key={s.shed_id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem'
          }}
        >
          <h3 style={{ margin: 0 }}>{[s.dairy_company, s.dairy_number, s.region].join(' ')}</h3>
          <span>{s.address}</span>
          {s.shed_products.length ? (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <div>Products</div>
              {s.shed_products.map(sp => {
                const product = data.products.find(p => p.id == sp.product_id)
                return (
                  <Box
                    key={sp.product_id}
                    sx={{
                      backgroundColor: product.brand === 'Deosan' ? 'highlight.teal' : 'highlight.silver'
                    }}
                  >
                    {[product?.name ? product.name : product.brand, product.pack_size, product.pack_size_unit].join(
                      ' '
                    )}
                  </Box>
                )
              })}
            </Box>
          ) : null}
          {s.tags.length ? (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <div>Tags</div>
              {s.tags.map(t => {
                return (
                  <Box
                    key={t.tag}
                    sx={{
                      backgroundColor: 'highlight.yellow'
                    }}
                  >
                    {t.tag}
                  </Box>
                )
              })}
            </Box>
          ) : null}
        </Box>
      )

      return (
        <>
          <Box
            sx={{
              backgroundColor: 'whitesmoke',
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNext fontSize='small' />}>
              <Link underline='hover' color='inherit' href='/contacts'>
                Contacts
              </Link>
              {contact_id ? (
                <Link underline='hover' color='inherit' href={`/contacts/${contact_id}`}>
                  {state.contact?.name ? state.contact.name : contact_id}
                </Link>
              ) : null}
            </Breadcrumbs>
          </Box>
          <Fab
            variant='extended'
            color='primary'
            onClick={() => page(`/canvass/create/${contact_id}`)}
            sx={{
              position: 'fixed',
              bottom: '2rem',
              right: '2rem',
              zIndex: 1000
            }}
          >
            <ContactPage sx={{ mr: 1 }} />
            New Canvass
          </Fab>
          {state.contact ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '1rem',
                marginBottom: '10rem',
                gap: '1rem'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem'
                }}
              >
                <h1>{state.contact.name ? state.contact.name : state.contact.id}</h1>
                {state.contact.trading_name ? <div>{state.contact.trading_name}</div> : null}
                {state.contact.email_address ? <div>{state.contact.email_address}</div> : null}
                {state.contact.phone_number ? <div>{state.contact.phone_number}</div> : null}
                {state.contact.tags.length ? (
                  <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                    <div>Tags</div>
                    {state.contact.tags.map(t => {
                      return (
                        <Box
                          key={t.tag}
                          sx={{
                            backgroundColor: 'highlight.yellow'
                          }}
                        >
                          {t.tag}
                        </Box>
                      )
                    })}
                  </Box>
                ) : null}
                <FormGroup
                  row
                  sx={{
                    marginTop: '1rem'
                  }}
                >
                  {state.contact.status === 'active' ? (
                    <Button variant='outlined' onClick={() => page(`/contacts/${contact_id}/deactivate`)}>
                      Deactivate contact
                    </Button>
                  ) : (
                    <Button variant='outlined' onClick={() => page(`/contacts/${contact_id}/activate`)}>
                      Activate contact
                    </Button>
                  )}
                </FormGroup>
              </Box>
              {state.contact?.canvasses.length ? (
                <>
                  <h2>Canvasses</h2>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: '1rem',
                      alignItems: 'center'
                    }}
                  >
                    {state.contact?.canvasses.map(c => {
                      return (
                        <Button
                          key={c.id}
                          variant='contained'
                          sx={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                          onClick={() => select_canvass(c)}
                        >
                          <span>{DateTime.fromISO(c.date).toFormat('dd MMMM yyyy')}</span>
                          <span>{c.agreement_status}</span>
                        </Button>
                      )
                    })}
                  </Box>
                </>
              ) : null}
              {sheds.filter(s => s.is_focus).length ? (
                <>
                  <h2>Sheds [Focused]</h2>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: '2rem'
                    }}
                  >
                    {sheds.filter(s => s.is_focus).map(s => shed_fragment(s))}
                  </Box>
                </>
              ) : null}
              {sheds.filter(s => !s.is_focus).length ? (
                <>
                  <h2>Sheds</h2>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: '2rem'
                    }}
                  >
                    {sheds.filter(s => !s.is_focus).map(s => shed_fragment(s))}
                  </Box>
                </>
              ) : null}
              {state.contact?.email_address == 'paul@y5.software' && (
                <Box>
                  <Button variant='contained' onClick={sentry_test()}>
                    Sentry Test
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: -1
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      )
    }
  ])

  inject('route', [
    '/contacts/:contact_id/deactivate',
    p => () => {
      const hub = useContext(HubContext)
      const { contact_id } = p.params
      const { data } = useContext(StateContext)
      const [state, set_state] = useState({})

      useEffect(() => {
        const contact = data?.contacts?.find(c => c.id == contact_id)
        if (data?.contacts && !contact) return page('/contacts')
        if (contact) {
          contact.name = [contact.first_name, contact.last_name].join(' ').trim()
          set_state(() => ({ contact }))
        }
      }, [data])

      const queue_deactivate_contact = contact_id => e => {
        e.preventDefault()
        // TODO: this shouldn't be an event, it should be a function
        hub.emit('queue', {
          collection: 'contacts',
          id: Number(contact_id),
          fields: {
            status: 'deleted'
          }
        })
        hub.emit('sync')
        page('/contacts')
      }

      return (
        <>
          <Box
            sx={{
              backgroundColor: 'whitesmoke',
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNext fontSize='small' />}>
              <Link underline='hover' color='inherit' href='/contacts'>
                Contacts
              </Link>
              {contact_id ? (
                <Link underline='hover' color='inherit' href={`/contacts/${contact_id}`}>
                  {state.contact?.name ? state.contact.name : contact_id}
                </Link>
              ) : null}
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              padding: '2rem'
            }}
          >
            {state.contact ? (
              <Grid container>
                <Grid item xs={12}>
                  <h2>Deactivate contact #{contact_id}?</h2>
                </Grid>
                <Box mt={5} sx={{ display: 'flex', gap: '1rem' }}>
                  <Button variant='outlined' onClick={() => page(`/contacts/${contact_id}`)}>
                    Cancel
                  </Button>
                  <Button variant='contained' onClick={queue_deactivate_contact(contact_id)}>
                    Deactivate contact
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: -1
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </>
      )
    }
  ])

  inject('route', [
    '/contacts/:contact_id/activate',
    p => () => {
      const hub = useContext(HubContext)
      const { contact_id } = p.params
      const { data } = useContext(StateContext)
      const [state, set_state] = useState({})

      useEffect(() => {
        const contact = data?.contacts?.find(c => c.id == contact_id)
        if (data?.contacts && !contact) return page('/contacts')
        if (contact) {
          contact.name = [contact.first_name, contact.last_name].join(' ').trim()
          set_state(() => ({ contact }))
        }
      }, [data])

      const queue_activate_contact = contact_id => e => {
        e.preventDefault()
        // TODO: this shouldn't be an event, it should be a function
        hub.emit('queue', {
          collection: 'contacts',
          id: contact_id,
          fields: {
            status: 'active'
          }
        })
        hub.emit('sync')
        page('/contacts')
      }

      return (
        <>
          <Box
            sx={{
              backgroundColor: 'whitesmoke',
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNext fontSize='small' />}>
              <Link underline='hover' color='inherit' href='/contacts'>
                Contacts
              </Link>
              {contact_id ? (
                <Link underline='hover' color='inherit' href={`/contacts/${contact_id}`}>
                  {state.contact?.name ? state.contact.name : contact_id}
                </Link>
              ) : null}
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              padding: '2rem'
            }}
          >
            {state.contact ? (
              <Grid container>
                <Grid item xs={12}>
                  <h2>Activate contact #{contact_id}?</h2>
                </Grid>
                <Box mt={5} sx={{ display: 'flex', gap: '1rem' }}>
                  <Button variant='outlined' onClick={() => page(`/contacts/${contact_id}`)}>
                    Cancel
                  </Button>
                  <Button variant='contained' onClick={queue_activate_contact(contact_id)}>
                    Activate contact
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: -1
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </>
      )
    }
  ])
})
