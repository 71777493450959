import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState } from 'react'
import page from 'page'
import {
  Breadcrumbs,
  Button,
  Box,
  Link,
  ListSubheader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Typography
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { NavigateNext } from '@mui/icons-material'
import { v4 as uuidv4 } from 'uuid'
import places from './places.json'
import CanvassProductEntry from './components/canvass-product-entry.jsx'

inject('pod', ({ HubContext, StateContext, IndexedDB }) => {
  inject('route', [
    '/canvass/create/:contact_id?',
    p => () => {
      const hub = useContext(HubContext)
      const { contact_id } = p.params
      const { data } = useContext(StateContext)
      const user = data?.user ?? {}
      const [state, set_state] = useState({
        id: uuidv4(),
        contact_id: contact_id,
        sheds: [],
        date: DateTime.now().toFormat('dd-MM-yyyy'),
        island: null,
        shed_id: null,
        dairy_number: null,
        note_farm: null,
        calving_date: null,
        ttx_volume_on_hand: null,
        days_remaining: null,
        agreement_status: 'No Business',
        reason_no_business: null,
        note_no_business: null,
        farm_details: {
          region: null,
          address: null,
          herd_size: null,
          contacts: [],
          products: []
        },
        recipients: [],
        regions: places,
        category_agreementstatus: {}
      })

      if (state.shed_id == null && state?.sheds.length === 1) {
        const shed = state.sheds[0]
        set_state(s => ({
          ...s,
          shed_id: shed.shed_id,
          dairy_number: shed.dairy_number,
          island: shed.island,
          farm_details: {
            ...s.farm_details,
            address: shed.address,
            region: shed.region,
            herd_size: shed.herd_size
          }
        }))
      }

      const product_enhancements = name => {
        switch (name) {
          case 'TeatX Plus':
            return {
              ordering_priority: 1,
              group: 'Teat Spray'
            }
          case 'TeatX':
            return {
              ordering_priority: 2,
              group: 'Teat Spray'
            }
          case 'Triodex':
            return {
              ordering_priority: 3,
              group: 'Teat Spray'
            }
          case 'Zoom Acid':
            return {
              ordering_priority: 4,
              group: 'Acid Detergent'
            }
          case 'Alkali Powder':
            return {
              ordering_priority: 5,
              group: 'Alkali Detergent'
            }
          case 'Chlor Alkali':
            return {
              ordering_priority: 6,
              group: 'Alkali Detergent'
            }
          case 'Hypo Chlor':
            return {
              ordering_priority: 7,
              group: 'Alkali Detergent'
            }
          case 'Alkali Liquid':
            return {
              ordering_priority: 8,
              group: 'Alkali Detergent'
            }
          case 'Bloat Control':
            return {
              ordering_priority: 9,
              group: 'Bloat Control'
            }
          case 'Combi 4 Chelate':
            return {
              ordering_priority: 10,
              group: 'Mineral'
            }
          case 'Combi 5 Chelate':
            return {
              ordering_priority: 11,
              group: 'Mineral'
            }
          case 'Amino 18':
            return {
              ordering_priority: 12,
              group: 'Mineral'
            }
          case 'Copper Chelate':
            return {
              ordering_priority: 13,
              group: 'Mineral'
            }
          case 'Cobalt Chelate':
            return {
              ordering_priority: 14,
              group: 'Mineral'
            }
          case 'Iodine Chelate':
            return {
              ordering_priority: 15,
              group: 'Mineral'
            }
          case 'Zinc Chelate':
            return {
              ordering_priority: 16,
              group: 'Mineral'
            }
          case 'Liquid Selenium':
            return {
              ordering_priority: 17,
              group: 'Mineral'
            }
          case 'Virsus':
            return {
              ordering_priority: 18,
              group: 'Disinfectant'
            }
          default:
            return {
              ordering_priority: 19,
              group: 'Other'
            }
        }
      }

      const validate_email = email => {
        if (email === '') return true
        const re = /\S{2,}@\S{2,}\.\S{2,}/
        return re.test(email)
      }
      const validate_phone = phone => {
        if (phone === '') return true
        const re = /^(\+64|64|0)\d{8,10}$/
        return re.test(phone)
      }
      const validate_name = name => {
        const re = /^.{2,}$/
        return re.test(name)
      }
      const validate_address = address => {
        const re = /^[a-zA-Z0-9,\s-]{2,}$/
        return re.test(address)
      }
      const validate_region = region => {
        const re = /^[a-zA-Z\s-]{2,}$/
        return re.test(region)
      }
      const validate_dairy_number = dairy_number => {
        const re = /^\d{1,}$/
        return re.test(dairy_number)
      }
      const validate_herd_size = herd_size => {
        if (herd_size === '') return true
        const re = /^\d{1,}$/
        return re.test(herd_size)
      }
      const validate_ttx_volume = ttx_volume_on_hand => {
        if (ttx_volume_on_hand === '') return true
        const re = /^\d{1,}$/
        return re.test(ttx_volume_on_hand)
      }
      const validate_days_remaining = days_remaining => {
        if (days_remaining === '') return true
        const re = /^\d{1,}$/
        return re.test(days_remaining)
      }

      const validate_date = date => {
        const d = date.replace(/[^\d-]/g, '')
        if (d == '--') return true
        const re = /^\d{2}-\d{2}-\d{4}$/
        return re.test(d)
      }

      const validate = (field, value) => {
        if (field.includes('email_address')) return validate_email(value)
        if (field.includes('phone_number')) return validate_phone(value)
        if (field.includes('name')) return validate_name(value)
        if (field.includes('address')) return validate_address(value)
        if (field.includes('region')) return validate_region(value)
        if (field.includes('dairy_number')) return validate_dairy_number(value)
        if (field.includes('herd_size')) return validate_herd_size(value)
        if (field.includes('ttx_volume_on_hand')) return validate_ttx_volume(value)
        if (field.includes('days_remaining')) return validate_days_remaining(value)
        if (field.includes('date')) return validate_date(value)
      }

      const should_display_ok = v => {
        return v === undefined ? false : v ? false : true
      }

      const rem = field => e => {
        if (field.includes('date')) {
          set_state(s => ({ ...s, [field]: e?.toFormat('dd-MM-yyyy') }))
          return
        }
        set_state(s => ({ ...s, [field]: e.target.value }))
      }

      const val = field => e => {
        const ok = validate(field, e.target.value)
        set_state(s => ({ ...s, [`${field}_ok`]: ok }))
      }

      const rem_farm_details = field => e => {
        set_state(s => ({
          ...s,
          farm_details: {
            ...s.farm_details,
            [field]: e.target.value
          }
        }))
      }

      const val_farm_details = field => e => {
        const ok = validate(field, e.target.value)
        set_state(s => ({
          ...s,
          farm_details: {
            ...s.farm_details,
            [`${field}_ok`]: ok
          }
        }))
      }

      const rem_farm_details_contact = (id, field) => e => {
        set_state(s => ({
          ...s,
          farm_details: {
            ...s.farm_details,
            contacts: s.farm_details.contacts.map(c => {
              if (c.id != id) return c
              return { ...c, [field]: e.target.value }
            })
          }
        }))
      }

      const val_farm_details_contact = (id, field) => e => {
        const ok = validate(field, e.target.value)
        set_state(s => ({
          ...s,
          farm_details: {
            ...s.farm_details,
            contacts: s.farm_details.contacts.map(c => {
              if (c.id != id) return c
              return { ...c, [`${field}_ok`]: ok }
            })
          }
        }))
      }

      const select_dairy_number = e => {
        const shed = state.sheds.find(s => s.dairy_number == e.target.value)
        set_state(s => ({
          ...s,
          shed_id: shed.shed_id,
          dairy_number: shed.dairy_number,
          island: shed.island,
          farm_details: {
            ...s.farm_details,
            address: shed.address,
            region: shed.region,
            herd_size: shed.herd_size
          }
        }))
      }

      const add_product = e => {
        const product_id = e.target.value
        const product = data.products.find(p => p.id == product_id)
        set_state(s => ({
          ...s,
          farm_details: {
            ...s.farm_details,
            products: [
              ...(s.farm_details.products ?? []),
              {
                _id: uuidv4(),
                product_id,
                name: product.name,
                category: product.category,
                quantity: 1,
                price: product.price,
                pack_size: product.pack_size,
                pack_size_unit: product.pack_size_unit,
                retailer_id: product.retailer_id
              }
            ]
          }
        }))
      }

      const add_contact = e => {
        set_state(s => ({
          ...s,
          farm_details: {
            ...s.farm_details,
            contacts: [
              ...(s.farm_details.contacts ?? []),
              {
                id: uuidv4(),
                contact_id: null,
                role: null,
                name: null,
                trading_name: null,
                email_address: null,
                phone_number: null,
                retailer_id: null
              }
            ]
          }
        }))
      }

      const add_available_contact = id => {
        const contact = state.available_contacts.find(c => c.id == id)
        set_state(s => ({
          ...s,
          farm_details: {
            ...s.farm_details,
            contacts: [
              ...(s.farm_details.contacts ?? []),
              {
                ...contact,
                name_ok: validate_name(contact.name),
                email_address_ok: validate_email(contact.email_address),
                phone_number_ok: validate_phone(contact.phone_number)
              }
            ]
          }
        }))
      }

      const remove_available_contact = id => {
        set_state(s => ({
          ...s,
          farm_details: {
            ...s.farm_details,
            contacts: s.farm_details.contacts.filter(c => c.id != id)
          }
        }))
      }

      const toggle_available_contact = id => e => {
        const contact = state.farm_details.contacts.find(c => c.id == id)
        if (contact) {
          remove_available_contact(id)
        } else {
          add_available_contact(id)
        }
      }

      const remove_contact = id => e => {
        set_state(s => ({
          ...s,
          farm_details: {
            ...s.farm_details,
            contacts: s.farm_details.contacts.filter(c => c.id != id)
          }
        }))
      }

      const use_price = (price, agreed_price) => {
        return Number(agreed_price && agreed_price !== price ? agreed_price : price)
      }

      const submit_canvass = async e => {
        e.preventDefault()

        const { queued_canvasses } = await IndexedDB.get_items(['queued_canvasses'])
        await IndexedDB.set_items({
          queued_canvasses: [
            ...(queued_canvasses ?? []),
            {
              id: state.id,
              date: state.date,
              island: state.island,
              shed_id: state.shed_id,
              dairy_number: Number(state.dairy_number),
              note_farm: state.note_farm,
              calving_date: state.calving_date,
              ttx_volume_on_hand: Number(state.ttx_volume_on_hand),
              days_remaining: Number(state.days_remaining),
              agreement_status: state.agreement_status,
              reason_no_business: state.reason_no_business,
              note_no_business: state.note_no_business,
              category_agreementstatus: state.category_agreementstatus,
              farm_details: {
                region: state.farm_details.region,
                address: state.farm_details.address,
                contacts: state.farm_details.contacts.map(c => ({
                  id: c.id,
                  contact_id: c.contact_id,
                  role: c.role,
                  name: c.name,
                  trading_name: c.trading_name,
                  email_address: c.email_address,
                  phone_number: c.phone_number,
                  retailer_id: c.retailer_id
                })),
                products: state.farm_details.products
                  .map(p => {
                    if (p.stagger && p.stagger.filter(s => Number(s.quantity) > 0).length > 0)
                      return p.stagger
                        .filter(s => Number(s.quantity) > 0)
                        .map(s => {
                          const { _id, stagger, ...rest } = p
                          return {
                            ...rest,
                            delivery_date: s.date.toFormat('dd-MM-yyyy'),
                            quantity: Number(s.quantity),
                            price: Number(s.price),
                            agreed_price: use_price(s.price, s.agreed_price)
                          }
                        })
                    else return [p]
                  })
                  .flat(),
                herd_size: state.farm_details.herd_size
              },
              recipients: state.recipients,
              created_at: Date.now()
            }
          ]
        })
        hub.emit('sync')
        if (contact_id) return page(`/contacts/${contact_id}`)
        if (state.contact_id) return page(`/contacts/${state.contact_id}`)
        page('/contacts')
      }

      const should_disable_submit = () => {
        const agreement_category = { 'TEAT-CARE': 'Teat Spray', 'SUP-MIN-TR': 'Mineral' }
        const products = state.farm_details.products
          .map(p => {
            if (p.stagger && p.stagger.filter(s => Number(s.quantity) > 0).length > 0)
              return p.stagger
                .filter(s => Number(s.quantity) > 0)
                .map(s => {
                  const { _id, stagger, ...rest } = p
                  return {
                    ...rest,
                    delivery_date: s.date ? s.date.toFormat('dd-MM-yyyy') : null,
                    quantity: Number(s.quantity),
                    price: Number(s.price),
                    agreed_price: use_price(s.price, s.agreed_price)
                  }
                })
            else return [p]
          })
          .flat()

        if (
          products.reduce((acc, p) => {
            const category = agreement_category[p.category] ?? 'Other'
            const is_confirmed = state.category_agreementstatus[category] == 'Confirmed'
            if (!is_confirmed) return acc
            return !(p.quantity && p.agreed_price && p.delivery_date && p.retailer_id)
          }, false)
        )
          return true

        if (
          state.agreement_status == 'Confirmed' &&
          products.reduce((acc, p) => {
            const category = agreement_category[p.category] ?? 'Other'
            return acc || state.category_agreementstatus[category] != 'Confirmed'
          }, false)
        )
          return true

        if (!state.date) return true
        if (!validate_dairy_number(state.dairy_number)) return true
        if (!state.island) return true
        if (!validate_region(state.region)) return true
        if (!state.farm_details.address) return true
        if (products.length == 0 && state.agreement_status != 'No Business') return true
        if (state.agreement_status == 'No Business' && !state.reason_no_business) return true
        if (products.some(p => !p.delivery_date || isNaN(p.quantity) || !p.quantity)) return true
        if (
          state.farm_details.contacts.some(
            p =>
              !p.role ||
              !validate_name(p.name) ||
              (p.email_address && !validate_email(p.email_address)) ||
              (p.phone_number && !validate_phone(p.phone_number))
          )
        )
          return true
        if (state.ttx_volume_on_hand && !validate_ttx_volume(state.ttx_volume_on_hand)) return true
        if (state.days_remaining && !validate_days_remaining(state.days_remaining)) return true
        if (JSON.stringify(state, null, 2).includes(`_ok": false`)) return true
        return false
      }

      const products = data?.products.filter(p => p.name && p.brand == 'Deosan' && p.price) ?? []

      const grouped_products = products
        .map(p => {
          const enhancements = product_enhancements(p.name)
          return {
            ...p,
            ...enhancements
          }
        })
        .sort((a, b) => {
          return a.ordering_priority - b.ordering_priority || Number(a.pack_size) - Number(b.pack_size)
        })
        .reduce((g, p) => {
          if (!g[p.group]) g[p.group] = []
          g[p.group].push(p)
          return g
        }, {})

      const roles = [
        'Contract Milker',
        'Farm Manager',
        'Farm Owner',
        'Farm Staff',
        'Other',
        'Sharemilker 50/50',
        'Sharemilker Lower Order'
      ]

      useEffect(() => {
        if (state.farm_details.products.length > 0 && state.agreement_status == 'No Business') {
          set_state(s => ({
            ...s,
            agreement_status: 'Pending',
            reason_no_business: null,
            note_no_business: null
          }))
        }
        if (state.farm_details.products.length == 0 && state.agreement_status != 'No Business') {
          set_state(s => ({
            ...s,
            agreement_status: 'No Business'
          }))
        }
      }, [state.farm_details.products])

      useEffect(() => {
        const other_contacts =
          data?.contacts.filter(c => {
            return c.sheds.find(s => s.shed_id == state.shed_id) && c.id != state.contact_id
          }) ?? []
        const contact = data?.contacts.find(c => c.id == state.contact_id)
        if (contact) {
          contact.name = [contact.first_name, contact.last_name].join(' ').trim()
          const shed = contact.sheds.find(s => s.shed_id == state.shed_id) ?? {}
          if (shed.region) {
            shed.region = toTitleCase(shed.region)
            if (shed.island && !state.regions[shed.island].some(r => r == shed.region)) {
              set_state(s => ({
                ...s,
                regions: {
                  ...s.regions,
                  [shed.island]: [...s.regions[shed.island], shed.region]
                }
              }))
            }
          }
          set_state(s => ({
            ...s,
            contact,
            sheds: contact.sheds,
            farm_details: {
              ...s.farm_details,
              contacts: [
                {
                  id: contact.id,
                  contact_id: contact.id,
                  role: shed.role,
                  trading_name: contact.trading_name,
                  email_address: contact.email_address,
                  phone_number: contact.phone_number,
                  ...contact
                }
              ].map(c => {
                const shed = c.sheds.find(s => s.shed_id == state.shed_id) ?? {}
                if (shed.region) {
                  shed.region = toTitleCase(shed.region)
                  if (shed.island && !state.regions[shed.island]?.some(r => r == shed.region)) {
                    set_state(s => ({
                      ...s,
                      regions: {
                        ...s.regions,
                        [shed.island]: [...s.regions[shed.island], shed.region]
                      }
                    }))
                  }
                }
                return {
                  ...c,
                  name_ok: validate_name(c.name),
                  email_address_ok: validate_email(c.email_address),
                  phone_number_ok: validate_phone(c.phone_number),
                  name: [c.first_name, c.last_name].join(' ').trim(),
                  retailer_id: shed.retailer_id
                }
              }),
              products: [...(s.farm_details.products ?? [])]
            },
            available_contacts: [contact, ...other_contacts].map(c => {
              const shed = c.sheds.find(s => s.shed_id == state.shed_id) ?? {}
              return {
                ...c,
                id: c.id,
                contact_id: c.id,
                role: shed.role,
                trading_name: c.trading_name,
                email_address: c.email_address,
                phone_number: c.phone_number,
                name: [c.first_name, c.last_name].join(' ').trim(),
                retailer_id: shed.retailer_id
              }
            })
          }))
        }
      }, [data, state.shed_id])

      const toTitleCase = str => {
        return str
          .toLowerCase()
          .trim()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      }

      useEffect(() => {
        if (!contact_id) {
          const contact =
            data?.contacts.find(c => c.sheds.find(s => s.dairy_number && s.dairy_number == state.dairy_number)) ?? {}
          contact.name = [contact.first_name, contact.last_name].join(' ').trim()
          const sheds = contact?.sheds ?? []
          const shed = sheds.find(s => s.dairy_number == state.dairy_number)
          if (shed) {
            if (shed.region) {
              shed.region = toTitleCase(shed.region)
              if (!state.regions[shed.island]?.some(r => r == shed.region)) {
                set_state(s => ({
                  ...s,
                  regions: {
                    ...s.regions,
                    [shed.island]: [...s.regions[shed.island], shed.region]
                  }
                }))
              }
            }
            set_state(s => ({
              ...s,
              contact,
              sheds,
              contact_id: contact.id,
              shed_id: shed.shed_id,
              dairy_number: shed.dairy_number,
              island: shed.island,
              farm_details: {
                ...s.farm_details,
                address: shed.address,
                region: shed.region,
                herd_size: shed.herd_size
              },
              available_contacts: [],
              recipients: []
            }))
          } else {
            set_state(s => ({
              ...s,
              contact: null,
              sheds: [],
              contact_id: null,
              island: null,
              shed_id: null,
              farm_details: {
                region: null,
                address: null,
                herd_size: null,
                contacts: [],
                products: []
              },
              available_contacts: [],
              recipients: []
            }))
          }
        }
      }, [state.dairy_number])

      const render_select_group = group => {
        const items = grouped_products[group].map(p => {
          return (
            <MenuItem key={p.id} value={p.id}>
              {p.name} - {p.pack_size}
              {p.pack_size_unit}
            </MenuItem>
          )
        })
        return [<ListSubheader>{group}</ListSubheader>, ...items]
      }

      const add_recipient = email_address => {
        set_state(s => ({
          ...s,
          recipients: [...(s.recipients ?? []), email_address]
        }))
      }

      const remove_recipient = email_address => {
        set_state(s => ({
          ...s,
          recipients: s.recipients.filter(e => e != email_address)
        }))
      }

      const toggle_recipient = email_address => e => {
        e.preventDefault()
        if (state.recipients.includes(email_address)) {
          remove_recipient(email_address)
        } else {
          add_recipient(email_address)
        }
      }

      const agreement_status_options = ['Pending', 'Confirmed', 'No Business']

      const change_agreement_status = e => {
        set_state(s => ({
          ...s,
          agreement_status: e.target.value
        }))
      }

      const reason_no_business_options = ['Sold', 'Moving', 'Lost Business', 'Other']

      const change_reason_no_business = e => {
        set_state(s => ({
          ...s,
          reason_no_business: e.target.value
        }))
      }

      const clean_string = str => {
        if (!str) return ''
        return str.split('\n').join(', ')
      }

      useEffect(() => {
        const island_retailers =
          data?.retailers?.filter(r => r.island == state.island).sort((a, b) => (a.name > b.name ? 1 : -1)) ?? []
        const region_retailers =
          data?.retailers
            ?.filter(r => r.region == state.farm_details.region)
            .sort((a, b) => (a.name > b.name ? 1 : -1)) ?? []
        set_state(s => ({ ...s, region_retailers, island_retailers }))
      }, [data?.retailers, state.island, state.farm_details.region])

      return (
        <>
          <Box
            sx={{
              backgroundColor: 'whitesmoke',
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNext fontSize='small' />}>
              <Link underline='hover' color='inherit' href='/contacts'>
                Contacts
              </Link>
              {contact_id ? (
                <Link underline='hover' color='inherit' href={`/contacts/${contact_id}`}>
                  {state.contact?.name ? state.contact.name : contact_id}
                </Link>
              ) : null}
            </Breadcrumbs>
          </Box>
          <FormControl sx={{ gap: '1rem', margin: '2rem 1rem 10rem 1rem' }}>
            {/* Sheds Section */}
            <FormGroup row>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  label='Canvass Date'
                  format='dd-MM-yyyy'
                  value={DateTime.fromFormat(state.date ?? '', 'dd-MM-yyyy')}
                  onChange={rem('date')}
                  slotProps={{
                    textField: {
                      onBlur: val('date'),
                      error: should_display_ok(state.date_ok),
                      helperText: should_display_ok(state.date_ok) && 'Invalid date.'
                    }
                  }}
                  sx={{ maxWidth: 200 }}
                />
              </LocalizationProvider>
            </FormGroup>
            <FormGroup row sx={{ gap: '1rem' }}>
              {!contact_id ? (
                <TextField
                  label='Dairy Number'
                  value={state.dairy_number ?? ''}
                  onChange={rem('dairy_number')}
                  onBlur={val('dairy_number')}
                  error={should_display_ok(state.dairy_number_ok)}
                  helperText={should_display_ok(state.dairy_number_ok) && 'Invalid dairy number.'}
                />
              ) : (
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id='dairy-number-select-label'>Dairy Number</InputLabel>
                  <Select
                    labelId='dairy-number-select-label'
                    id='dairy-number'
                    onChange={select_dairy_number}
                    value={state.dairy_number ?? ''}
                  >
                    {state.sheds?.map((s, i) => {
                      return (
                        <MenuItem key={s.dairy_number} value={s.dairy_number}>
                          {`${s.dairy_number}${s.is_focus ? ' (Focus)' : ''}`}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id='island-select-label'>Island</InputLabel>
                <Select
                  labelId='island-select-label'
                  id='island-select'
                  value={state.island ?? ''}
                  onChange={rem('island')}
                >
                  <MenuItem value='north'>North</MenuItem>
                  <MenuItem value='south'>South</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id='region-select-label'>Region</InputLabel>
                <Select
                  labelId='region-select-label'
                  id='region-select'
                  value={state.farm_details.region ? state.farm_details.region : ''}
                  onChange={rem_farm_details('region')}
                >
                  {(state.regions[state.island] ?? []).map(v => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup>
              <TextField
                label='Shed Address'
                value={clean_string(state.farm_details.address) ?? ''}
                onChange={rem_farm_details('address')}
                onBlur={val_farm_details('address')}
                error={should_display_ok(state.farm_details.address_ok)}
                helperText={should_display_ok(state.farm_details.address_ok) && 'Invalid address.'}
              />
            </FormGroup>
            <FormGroup row sx={{ gap: '1rem' }}>
              <TextField
                label='Herd Size'
                value={state.farm_details.herd_size ?? ''}
                onChange={rem_farm_details('herd_size')}
                onBlur={val_farm_details('herd_size')}
                error={should_display_ok(state.farm_details.herd_size_ok)}
                helperText={should_display_ok(state.farm_details.herd_size_ok) && 'Invalid herd size.'}
              />
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  label='Calving Date'
                  format='dd-MM-yyyy'
                  onChange={rem('calving_date')}
                  slotProps={{
                    textField: {
                      onBlur: val('calving_date'),
                      error: should_display_ok(state.calving_date_ok),
                      helperText: should_display_ok(state.calving_date_ok) && 'Invalid calving date.'
                    }
                  }}
                  sx={{ maxWidth: 200 }}
                />
              </LocalizationProvider>
              <TextField
                label='TTX Volume on Hand'
                onChange={rem('ttx_volume_on_hand')}
                onBlur={val('ttx_volume_on_hand')}
                error={should_display_ok(state.ttx_volume_ok)}
                helperText={should_display_ok(state.ttx_volume_ok) && 'Invalid TTX volume.'}
              />
              <TextField
                label='Days Remaining'
                onChange={rem('days_remaining')}
                onBlur={val('days_remaining')}
                error={should_display_ok(state.days_remaining_ok)}
                helperText={should_display_ok(state.days_remaining_ok) && 'Invalid days remaining.'}
              />
            </FormGroup>

            {/* Contacts Section */}
            {state.available_contacts?.length > 0 ? (
              <>
                <h2 style={{ margin: '1rem 0 0 0' }}>Available Contacts</h2>
                <FormGroup sx={{ flexDirection: 'row' }}>
                  {state.available_contacts.map(c => {
                    const is_added = state.farm_details.contacts.some(c2 => c2.contact_id === c.contact_id)
                    return (
                      <FormControlLabel
                        key={c.id}
                        control={<Checkbox checked={is_added} onChange={toggle_available_contact(c.id)} />}
                        label={c.name}
                      />
                    )
                  })}
                </FormGroup>
              </>
            ) : null}
            <h2 style={{ margin: '1rem 0 0 0' }}>Contacts</h2>
            {state.farm_details.contacts?.length > 0 ? (
              <FormGroup row sx={{ gap: '1rem' }}>
                {state.farm_details.contacts.map((c, i) => (
                  <FormGroup key={c.id} sx={{ gap: '1rem' }}>
                    <FormControl>
                      <InputLabel id='role-select-label'>Role</InputLabel>
                      <Select
                        labelId='role-select-label'
                        id='role-select'
                        value={c.role ?? ''}
                        onChange={rem_farm_details_contact(c.id, 'role')}
                      >
                        {roles.map(v => (
                          <MenuItem key={v} value={v}>
                            {v}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      label='Name'
                      value={c.name ?? ''}
                      onChange={rem_farm_details_contact(c.id, 'name')}
                      onBlur={val_farm_details_contact(c.id, 'name')}
                      error={should_display_ok(c.name_ok)}
                      helperText={should_display_ok(c.name_ok) && 'Invalid name.'}
                    />
                    <TextField
                      label='Trading Name'
                      value={c.trading_name ?? ''}
                      onChange={rem_farm_details_contact(c.id, 'trading_name')}
                    />
                    <TextField
                      label='Email'
                      value={c.email_address ?? ''}
                      onChange={rem_farm_details_contact(c.id, 'email_address')}
                      onBlur={val_farm_details_contact(c.id, 'email_address')}
                      error={should_display_ok(c.email_address_ok)}
                      helperText={should_display_ok(c.email_address_ok) && 'Invalid email address.'}
                    />
                    <TextField
                      label='Phone'
                      value={c.phone_number ?? ''}
                      onChange={rem_farm_details_contact(c.id, 'phone_number')}
                      onBlur={val_farm_details_contact(c.id, 'phone_number')}
                      error={should_display_ok(c.phone_number_ok)}
                      helperText={
                        should_display_ok(c.phone_number_ok) &&
                        'Invalid phone number. Landline numbers must have their area code.'
                      }
                    />
                    <FormControl sx={{ minWidth: 200 }}>
                      <InputLabel id='retailer-select-label'>Retailer</InputLabel>
                      <Select
                        labelId='retailer-select-label'
                        id='retailer-select'
                        value={c.retailer_id ? c.retailer_id : ''}
                        onChange={rem_farm_details_contact(c.id, 'retailer_id')}
                      >
                        {state.region_retailers.length ? <ListSubheader>By Region</ListSubheader> : null}
                        {state.region_retailers.map(r => (
                          <MenuItem key={r.id} value={r.id}>
                            {r.name}
                          </MenuItem>
                        ))}
                        {state.island_retailers.length ? <ListSubheader>By Island</ListSubheader> : null}
                        {state.island_retailers.map(r => (
                          <MenuItem key={r.id} value={r.id}>
                            {r.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormGroup row>
                      <Button variant='contained' onClick={remove_contact(c.id)}>
                        Remove
                      </Button>
                    </FormGroup>
                  </FormGroup>
                ))}
              </FormGroup>
            ) : null}
            <FormGroup row>
              <Button variant='contained' onClick={add_contact}>
                Add Contact
              </Button>
            </FormGroup>

            {/* Farm Notes Section */}
            <h2 style={{ margin: '1rem 0 0 0' }}>Farm Note</h2>
            <FormGroup>
              <TextField onChange={rem('note_farm')} multiline minRows={3} />
            </FormGroup>

            {/* Products Section */}
            <h2 style={{ margin: '1rem 0 0 0' }}>Products</h2>
            {state.farm_details.products.length > 0 &&
              state.farm_details.products.map((p, i) => {
                return (
                  <CanvassProductEntry
                    product={products.find(pr => pr.id === p.product_id)}
                    p={p}
                    retailers={data.retailers}
                    products={data.products}
                    state={state}
                    set_state={set_state}
                    contacts={state.farm_details.contacts}
                  />
                )
              })}
            <FormGroup row>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id='products-label' htmlFor='products'>
                  Product List
                </InputLabel>
                <Select labelId='products-label' id='products' value={''} onChange={add_product}>
                  {Object.keys(grouped_products).map(pk => render_select_group(pk))}
                </Select>
              </FormControl>
            </FormGroup>

            {/* Product Status Section */}
            {state.farm_details.products.length > 0 && (
              <>
                <h2 style={{ margin: '1rem 0 0 0' }}>Product Status</h2>
                {state.farm_details.products
                  .reduce((a, c) => [...a, c.category], [])
                  .reduce((a, c) => {
                    {
                      /** All options: 26/02/2024
                       * TEAT-CARE
                       * DET-ACID
                       * DET-ALK
                       * SUP-MIN-TR
                       * DISINFECT
                       */
                    }
                    const agreement_category = { 'TEAT-CARE': 'Teat Spray', 'SUP-MIN-TR': 'Mineral' }[c] ?? 'Other'
                    if (a.includes(agreement_category)) return a
                    return [...a, agreement_category]
                  }, [])
                  .map(category => (
                    <FormGroup row sx={{ gap: '1rem' }}>
                      <Typography variant='h6'>{category}</Typography>
                      <RadioGroup
                        name={`{category}-radio-group`}
                        defaultValue='Pending'
                        value={state.category_agreementstatus[category] ?? 'Pending'}
                        onChange={e =>
                          set_state(s => ({
                            ...s,
                            category_agreementstatus: {
                              ...s.category_agreementstatus,
                              [category]: e.target.value
                            }
                          }))
                        }
                        sx={{
                          flexDirection: 'row'
                        }}
                      >
                        {['Pending', 'Confirmed'].map(option => (
                          <FormControlLabel
                            key={`${category}_${option}`}
                            value={option}
                            control={<Radio />}
                            label={option}
                          />
                        ))}
                      </RadioGroup>
                    </FormGroup>
                  ))}
              </>
            )}

            {/* Agreement Status Section */}
            <h2 style={{ margin: '1rem 0 0 0' }}>Agreement Status</h2>
            <FormGroup>
              <RadioGroup
                aria-labelledby='agreement-status-radio-group-label'
                name='agreement-status-radio-group'
                defaultValue={state.farm_details.products.length === 0 ? 'No Business' : 'Pending'}
                value={state.agreement_status}
                onChange={change_agreement_status}
                sx={{
                  flexDirection: 'row'
                }}
              >
                {agreement_status_options.map(option => {
                  const is_disabled = {
                    Pending: state.farm_details.products.length === 0,
                    Confirmed: state.farm_details.products.length === 0,
                    'No Business': state.farm_details.products.length > 0
                  }[option]
                  return (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={option}
                      disabled={is_disabled}
                    />
                  )
                })}
              </RadioGroup>
            </FormGroup>
            {state.agreement_status === 'No Business' && !state.farm_details.products.length ? (
              <>
                <h2 style={{ margin: '1rem 0 0 0' }}>Reason for No Business</h2>
                <FormGroup>
                  <RadioGroup
                    aria-labelledby='reason-no-business-radio-group-label'
                    name='reason-no-business-radio-group'
                    value={state.reason_no_business}
                    onChange={change_reason_no_business}
                    sx={{
                      flexDirection: 'row'
                    }}
                  >
                    {reason_no_business_options.map(option => {
                      return <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                    })}
                  </RadioGroup>
                </FormGroup>
                <FormGroup>
                  <TextField onChange={rem('note_no_business')} multiline minRows={3} />
                </FormGroup>
              </>
            ) : null}
            {state.farm_details.products.length > 0 ? (
              <>
                <h2 style={{ margin: '1rem 0 0 0' }}>Recipients of Agreement to Supply</h2>
                <FormGroup row sx={{ gap: '1rem' }}>
                  {[...state.farm_details.contacts, user].map(c => {
                    const is_added = [...state.recipients].includes(c.email_address)
                    if (validate_email(c.email_address))
                      return (
                        <FormControlLabel
                          key={c.email_address}
                          control={<Checkbox checked={is_added} />}
                          label={c.email_address}
                          onClick={toggle_recipient(c.email_address)}
                        />
                      )
                  })}
                </FormGroup>
              </>
            ) : null}

            {/* Submit Canvas  */}
            <FormGroup row sx={{ marginTop: '2rem' }}>
              <Button variant='contained' onClick={e => submit_canvass(e)} disabled={should_disable_submit()}>
                Submit Canvass
              </Button>
            </FormGroup>
          </FormControl>
        </>
      )
    }
  ])
})
