import inject from 'seacreature/lib/inject'
import { useContext, useEffect, useState } from 'react'
import page from 'page'
import {
  Fab,
  CircularProgress,
  Breadcrumbs,
  Button,
  Box,
  InputAdornment,
  Link,
  ListSubheader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Typography
} from '@mui/material'

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { NavigateNext, ContactPage } from '@mui/icons-material'
import { v4 as uuidv4 } from 'uuid'
import ConfirmSubmitCanvas from './components/canvass-confirm-submit'
import CanvassContacts from './components/canvass-contacts'
import CanvassProductEntry from './components/canvass-product-entry'

inject('pod', ({ HubContext, StateContext, IndexedDB }) => {
  inject('route', [
    '/contacts/:contact_id/canvasses/:canvass_id',
    p => () => {
      const hub = useContext(HubContext)
      const { contact_id, canvass_id } = p.params
      const { data } = useContext(StateContext)
      const [state, set_state] = useState({})
      const [show_confirm, set_show_confirm] = useState(false)
      const [contact_canditates, set_contact_canditates] = useState([])

      useEffect(() => {
        const contact = data?.contacts?.find(c => c.id == contact_id)
        if (data?.contacts && !contact) return page('/contacts')
        if (contact) {
          contact.name = [contact.first_name, contact.last_name].join(' ').trim()
          const canvass = contact.canvasses.find(c => c.id == canvass_id)
          const shed = contact.sheds.find(s => s.shed_id == canvass.shed_id)
          contact.retailer_id = canvass.retailer_id

          set_state(() => ({
            contact,
            user: data.user,
            canvass: {
              id: canvass.id,
              contact_id: canvass.contact_id,
              // sheds: [],
              date: canvass.date,
              island: shed.island,
              shed_id: canvass.shed_id,
              dairy_number: shed.dairy_number,
              note_farm: canvass.note_farm,
              calving_date: canvass.calving_date,
              ttx_volume_on_hand: canvass.ttx_volume_on_hand,
              days_remaining: canvass.days_remaining,
              agreement_status: canvass.agreement_status,
              reason_no_business: canvass.reason_no_business,
              note_no_business: canvass.canvas_note_no_business,
              farm_details: {
                region: shed.region,
                address: shed.address,
                herd_size: shed.herd_size,
                contacts: [
                  {
                    id: contact.id,
                    contact_id: contact.id,
                    role: shed.role,
                    name: [contact.first_name, contact.last_name].join(' ').trim(),
                    trading_name: contact.trading_name,
                    email_address: contact.email_address,
                    phone_number: contact.phone_number,
                    retailer_id: canvass.retailer_id
                  }
                ],
                products: canvass.products
                  .reduce((acc, p) => {
                    const staggergroup = acc.find(ap => ap.product_id == p.product_id)
                    if (!staggergroup) acc.push({ product_id: p.product_id, entries: [p] })
                    else staggergroup.entries.push(p)
                    return acc
                  }, [])
                  .map(staggergroup => {
                    const { entries } = staggergroup
                    const with_agreed_price = entries.filter(e => e.agreed_price)
                    const group = { ...entries[0] }
                    group._id = uuidv4()
                    group.agreed_price =
                      with_agreed_price.length > 0
                        ? with_agreed_price.reduce((acc, c) => acc + Number(c.agreed_price), 0)
                        : null
                    group.price = entries.reduce((acc, c) => acc + Number(c.price), 0)
                    group.quantity = entries.reduce((acc, c) => acc + Number(c.quantity), 0)
                    if (entries.length > 1)
                      group.stagger = entries.map(e => ({
                        agreed_price: e.agreed_price,
                        date: e.delivery_date
                          ? DateTime.fromFormat(e.delivery_date, 'dd-MM-yyyy', { zone: 'Pacific/Auckland' })
                          : DateTime.now(),
                        price: e.price,
                        quantity: e.quantity
                      }))
                    return group
                  })
              },
              created_at: canvass.created_at,
              available_recipients: canvass.recipients,
              recipients: [],
              secondary_contacts: canvass.secondary_contacts,
              category_status: canvass.category_status
              // regions: places,
              // display_reason_no_business_fields: false
            },
            shed
          }))

          const contact_canditates = canvass.contact_canditates
            .filter(({ contact_id }) => contact_id != canvass.contact_id)
            .filter(({ contact_id }) => !canvass.secondary_contacts.map(c => c.id).includes(contact_id))
            .map(contact => {
              const { first_name, last_name, trading_name } = data.contacts.find(c => c.id == contact.contact_id)
              return {
                name: `${first_name ?? ''} ${last_name ?? ''} (${trading_name})`,
                ...contact
              }
            })
          set_contact_canditates(contact_canditates)
        }
      }, [data])

      const agreement_status_options =
        state.canvass?.agreement_status !== 'No Business' ? ['Pending', 'Confirmed'] : ['No Business']

      const change_agreement_status = e => {
        set_state(s => ({
          ...s,
          canvass: {
            ...s.canvass,
            agreement_status: e.target.value
          }
        }))
      }

      const change_product_agreement_status = (e, product) => {
        product.agreement_status = e.target.value
        set_state(s => ({
          ...s,
          canvass: {
            ...s.canvass,
            farm_details: {
              ...s.canvass.farm_details,
              products: s.canvass.farm_details.products.map(p => {
                if (p.id != product.product_id) return p
                return {
                  ...p,
                  agreement_status: e.target.value
                }
              })
            }
          }
        }))
      }

      const add_product = e => {
        const product_id = e.target.value
        const product = data.products.find(p => p.id == product_id)
        set_state(s => ({
          ...s,
          canvass: {
            ...s.canvass,
            farm_details: {
              ...s.canvass.farm_details,
              products: [
                ...(s.canvass.farm_details.products ?? []),
                {
                  _id: uuidv4(),
                  product_id,
                  name: product.name,
                  category: product.category,
                  quantity: 1,
                  price: product.price,
                  pack_size: product.pack_size,
                  pack_size_unit: product.pack_size_unit,
                  agreement_status: 'Pending'
                }
              ]
            }
          }
        }))
      }

      const update_product = (_id, field) => e => {
        set_state(s => ({
          ...s,
          canvass: {
            ...s.canvass,
            farm_details: {
              ...s.canvass.farm_details,
              products: s.canvass.farm_details.products.map(p => {
                if (p._id != _id) return p
                if (field.includes('date')) {
                  return { ...p, [field]: e?.toFormat('dd-MM-yyyy') }
                }
                if (field == 'quantity') {
                  const product = data.products.find(pp => pp.id == p.product_id)
                  return {
                    ...p,
                    [field]: Number(e.target.value) ? e.target.value : '',
                    price: Number(e.target.value)
                      ? (product.price * e.target.value)
                          .toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'NZD'
                          })
                          .replace('NZ$', '')
                      : ''
                  }
                }
                if (field == 'retailer_id') {
                  const retailer = data.retailers.find(r => r.id == e.target.value)
                  return {
                    ...p,
                    [field]: e.target.value,
                    retailer_name: retailer.name
                  }
                }
                return { ...p, [field]: e.target.value }
              })
            }
          }
        }))
      }

      const val_product = (_id, field) => e => {
        const ok = validate(field, e.target.value)
        set_state(s => ({
          ...s,
          canvass: {
            ...s.canvass,
            farm_details: {
              ...s.canvass.farm_details,
              products: s.canvass.farm_details.products.map(p => {
                if (p._id != _id) return p
                return { ...p, [`${field}_ok`]: ok }
              })
            }
          }
        }))
      }

      const remove_product = _id => e => {
        set_state(s => ({
          ...s,
          canvass: {
            ...s.canvass,
            farm_details: {
              ...s.canvass.farm_details,
              products: s.canvass.farm_details.products.filter(p => p._id != _id)
            }
          }
        }))
      }

      const validate_email = email => {
        if (email === '') return true
        const re = /\S{2,}@\S{2,}\.\S{2,}/
        return re.test(email)
      }
      const validate_phone = phone => {
        if (phone === '') return true
        const re = /^(\+64|64|0)\d{8,10}$/
        return re.test(phone)
      }
      const validate_name = name => {
        const re = /^[a-zA-Z\s-]{2,}$/
        return re.test(name)
      }
      const validate_address = address => {
        const re = /^[a-zA-Z0-9,\s-]{2,}$/
        return re.test(address)
      }
      const validate_region = region => {
        const re = /^[a-zA-Z\s-]{2,}$/
        return re.test(region)
      }
      const validate_dairy_number = dairy_number => {
        const re = /^\d{1,}$/
        return re.test(dairy_number)
      }
      const validate_herd_size = herd_size => {
        if (herd_size === '') return true
        const re = /^\d{1,}$/
        return re.test(herd_size)
      }
      const validate_ttx_volume = ttx_volume_on_hand => {
        if (ttx_volume_on_hand === '') return true
        const re = /^\d{1,}$/
        return re.test(ttx_volume_on_hand)
      }
      const validate_days_remaining = days_remaining => {
        if (days_remaining === '') return true
        const re = /^\d{1,}$/
        return re.test(days_remaining)
      }
      const validate_quantity = quantity => {
        if (quantity === '') return false
        const re = /^\d{1,}$/
        return re.test(quantity)
      }
      const validate_agreed_price = agreed_price => {
        if (agreed_price === '') return true
        const re = /^\d{2,}(\.\d{2})?$/
        return re.test(agreed_price)
      }

      const validate_date = date => {
        const d = date.replace(/[^\d-]/g, '')
        if (d == '--') return true
        const re = /^\d{2}-\d{2}-\d{4}$/
        return re.test(d)
      }

      const validate = (field, value) => {
        if (field.includes('email_address')) return validate_email(value)
        if (field.includes('phone_number')) return validate_phone(value)
        if (field.includes('name')) return validate_name(value)
        if (field.includes('address')) return validate_address(value)
        if (field.includes('region')) return validate_region(value)
        if (field.includes('dairy_number')) return validate_dairy_number(value)
        if (field.includes('herd_size')) return validate_herd_size(value)
        if (field.includes('ttx_volume_on_hand')) return validate_ttx_volume(value)
        if (field.includes('days_remaining')) return validate_days_remaining(value)
        if (field.includes('quantity')) return validate_quantity(value)
        if (field.includes('agreed_price')) return validate_agreed_price(value)
        if (field.includes('date')) return validate_date(value)
      }

      const should_display_ok = v => {
        return v === undefined ? false : v ? false : true
      }

      const products = data?.products.filter(p => p.name && p.brand == 'Deosan' && p.price) ?? []

      const product_enhancements = name => {
        switch (name) {
          case 'TeatX Plus':
            return {
              ordering_priority: 1,
              group: 'Teat Spray'
            }
          case 'TeatX':
            return {
              ordering_priority: 2,
              group: 'Teat Spray'
            }
          case 'Triodex':
            return {
              ordering_priority: 3,
              group: 'Teat Spray'
            }
          case 'Zoom Acid':
            return {
              ordering_priority: 4,
              group: 'Acid Detergent'
            }
          case 'Alkali Powder':
            return {
              ordering_priority: 5,
              group: 'Alkali Detergent'
            }
          case 'Chlor Alkali':
            return {
              ordering_priority: 6,
              group: 'Alkali Detergent'
            }
          case 'Hypo Chlor':
            return {
              ordering_priority: 7,
              group: 'Alkali Detergent'
            }
          case 'Alkali Liquid':
            return {
              ordering_priority: 8,
              group: 'Alkali Detergent'
            }
          case 'Bloat Control':
            return {
              ordering_priority: 9,
              group: 'Bloat Control'
            }
          case 'Combi 4 Chelate':
            return {
              ordering_priority: 10,
              group: 'Mineral'
            }
          case 'Combi 5 Chelate':
            return {
              ordering_priority: 11,
              group: 'Mineral'
            }
          case 'Amino 18':
            return {
              ordering_priority: 12,
              group: 'Mineral'
            }
          case 'Copper Chelate':
            return {
              ordering_priority: 13,
              group: 'Mineral'
            }
          case 'Cobalt Chelate':
            return {
              ordering_priority: 14,
              group: 'Mineral'
            }
          case 'Iodine Chelate':
            return {
              ordering_priority: 15,
              group: 'Mineral'
            }
          case 'Zinc Chelate':
            return {
              ordering_priority: 16,
              group: 'Mineral'
            }
          case 'Liquid Selenium':
            return {
              ordering_priority: 17,
              group: 'Mineral'
            }
          case 'Virsus':
            return {
              ordering_priority: 18,
              group: 'Disinfectant'
            }
          default:
            return {
              ordering_priority: 19,
              group: 'Other'
            }
        }
      }

      const grouped_products = products
        .map(p => {
          const enhancements = product_enhancements(p.name)
          return {
            ...p,
            ...enhancements
          }
        })
        .sort((a, b) => {
          return a.ordering_priority - b.ordering_priority || Number(a.pack_size) - Number(b.pack_size)
        })
        .reduce((g, p) => {
          if (!g[p.group]) g[p.group] = []
          g[p.group].push(p)
          return g
        }, {})

      const render_select_group = group => {
        const items = grouped_products[group].map(p => {
          return (
            <MenuItem key={p.id} value={p.id}>
              {p.name} - {p.pack_size}
              {p.pack_size_unit}
            </MenuItem>
          )
        })
        return [<ListSubheader>{group}</ListSubheader>, ...items]
      }

      const use_price = (price, agreed_price) => {
        return Number(agreed_price && agreed_price !== price ? agreed_price : price)
      }

      const reason_no_business_options = ['Price', 'Service', 'Relationship', 'Other']

      const change_reason_no_business = e => {
        set_state(s => ({
          ...s,
          canvass: {
            ...s.canvass,
            reason_no_business: e.target.value
          }
        }))
      }

      const add_recipient = email_address => {
        set_state(s => ({
          ...s,
          canvass: {
            ...s.canvass,
            recipients: [...(s.canvass.recipients ?? []), email_address]
          }
        }))
      }

      const remove_recipient = email_address => {
        set_state(s => ({
          ...s,
          canvass: {
            ...s.canvass,
            recipients: s.canvass.recipients.filter(e => e != email_address)
          }
        }))
      }

      const toggle_recipient = email_address => e => {
        e.preventDefault()
        if (state.canvass.recipients.includes(email_address)) {
          remove_recipient(email_address)
        } else {
          add_recipient(email_address)
        }
      }

      useEffect(() => {
        const island_retailers =
          data?.retailers?.filter(r => r.island == state.canvass?.island).sort((a, b) => (a.name > b.name ? 1 : -1)) ??
          []
        const region_retailers =
          data?.retailers
            ?.filter(r => r.region == state.canvass?.farm_details.region)
            .sort((a, b) => (a.name > b.name ? 1 : -1)) ?? []
        set_state(s => ({ ...s, region_retailers, island_retailers }))
      }, [data?.retailers, state.canvass?.island, state.canvass?.farm_details.region])

      const should_disable_submit = () => {
        const agreement_category = { 'TEAT-CARE': 'Teat Spray', 'SUP-MIN-TR': 'Mineral' }
        const products = state.canvass.farm_details.products
          .map(p => {
            if (p.stagger && p.stagger.filter(s => Number(s.quantity) > 0).length > 0)
              return p.stagger
                .filter(s => Number(s.quantity) > 0)
                .map(s => {
                  const { _id, stagger, ...rest } = p
                  return {
                    ...rest,
                    delivery_date: s.date ? s.date.toFormat('dd-MM-yyyy') : null,
                    quantity: Number(s.quantity),
                    price: Number(s.price),
                    agreed_price: use_price(s.price, s.agreed_price)
                  }
                })
            else return [p]
          })
          .flat()

        if (
          products.reduce((acc, p) => {
            const category = agreement_category[p.category] ?? 'Other'
            const is_confirmed = state.canvass.category_status[category] == 'Confirmed'
            if (!is_confirmed) return acc
            return !(p.quantity && p.agreed_price && p.delivery_date && p.retailer_id)
          }, false)
        )
          return true

        if (
          state.agreement_status == 'Confirmed' &&
          products.reduce((acc, p) => {
            const category = agreement_category[p.category] ?? 'Other'
            return acc || state.canvass.category_status[category] != 'Confirmed'
          }, false)
        )
          return true

        if (!state.canvass.date) return true
        if (!validate_dairy_number(state.canvass.dairy_number)) return true
        if (!state.canvass.island) return true
        if (!validate_region(state.canvass.region)) return true
        if (!state.canvass.farm_details.address) return true
        if (state.canvass.farm_details.products.some(p => !p.delivery_date || isNaN(p.quantity) || !p.quantity))
          return true
        if (!state.canvass.farm_details.products.length) return true
        if (
          state.canvass.farm_details.contacts.some(
            p =>
              !p.role ||
              !validate_name(p.name) ||
              (p.email_address && !validate_email(p.email_address)) ||
              (p.phone_number && !validate_phone(p.phone_number))
          )
        )
          return true
        if (state.canvass.ttx_volume_on_hand && !validate_ttx_volume(state.canvass.ttx_volume_on_hand)) return true
        if (state.canvass.days_remaining && !validate_days_remaining(state.canvass.days_remaining)) return true
        if (!state.canvass.agreement_status) return true
        if (JSON.stringify(state, null, 2).includes(`_ok": false`)) return true
        return false
      }

      const submit_canvass = async mark_complete => {
        const { queued_canvasses } = await IndexedDB.get_items(['queued_canvasses'])
        await IndexedDB.set_items({
          queued_canvasses: [
            ...(queued_canvasses ?? []),
            {
              id: state.canvass.id,
              date: DateTime.fromISO(state.canvass.date).toFormat('dd-MM-yyyy'),
              island: state.canvass.island,
              shed_id: state.canvass.shed_id,
              dairy_number: Number(state.canvass.dairy_number),
              note_farm: state.canvass.note_farm,
              calving_date: state.canvass.calving_date,
              ttx_volume_on_hand: Number(state.canvass.ttx_volume_on_hand),
              days_remaining: Number(state.canvass.days_remaining),
              agreement_status: state.canvass.agreement_status,
              reason_no_business: state.canvass.reason_no_business,
              note_no_business: state.canvass.note_no_business,
              category_status: state.canvass.category_status,
              farm_details: {
                region: state.canvass.farm_details.region,
                address: state.canvass.farm_details.address,
                herd_size: state.shed.herd_size,
                contacts: state.canvass.farm_details.contacts.map(c => ({
                  id: c.id,
                  contact_id: c.contact_id,
                  role: c.role,
                  name: c.name,
                  trading_name: c.trading_name,
                  email_address: c.email_address,
                  phone_number: c.phone_number,
                  retailer_id: c.retailer_id
                })),
                products: state.canvass.farm_details.products
                  .map(p => {
                    if (!p.stagger)
                      return [
                        {
                          product_id: p.product_id,
                          name: p.name,
                          delivery_date: p.delivery_date,
                          quantity: Number(p.quantity),
                          price: Number(`${p.price}`.replaceAll(',', '')),
                          agreed_price: use_price(p.price, p.agreed_price),
                          pack_size: p.pack_size,
                          pack_size_unit: p.pack_size_unit,
                          category: p.category,
                          retailer_id: p.retailer_id,
                          retailer_name: p.retailer_name,
                          agreement_status: mark_complete.includes(p.product_id) ? 'Confirmed' : p.agreement_status
                        }
                      ]
                    else {
                      return p.stagger.map(s => ({
                        product_id: p.product_id,
                        name: p.name,
                        delivery_date: s.date.toFormat('dd-MM-yyyy'),
                        quantity: Number(s.quantity),
                        price: Number(`${s.price}`.replaceAll(',', '')),
                        agreed_price: use_price(s.price, s.agreed_price),
                        pack_size: p.pack_size,
                        pack_size_unit: p.pack_size_unit,
                        category: p.category,
                        retailer_id: p.retailer_id,
                        retailer_name: p.retailer_name,
                        agreement_status: mark_complete.includes(p.product_id) ? 'Confirmed' : p.agreement_status
                      }))
                    }
                  })
                  .flat()
              },
              herd_size: state.shed.herd_size,
              recipients: state.canvass.recipients,
              updated_at: Date.now()
            }
          ]
        })

        hub.emit('test sync')
        hub.emit('sync')
        if (contact_id) return page(`/contacts/${contact_id}`)
        if (state.canvass.contact_id) return page(`/contacts/${state.canvass.contact_id}`)
        page('/contacts')
      }

      return (
        <>
          <Box
            sx={{
              backgroundColor: 'whitesmoke',
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNext fontSize='small' />}>
              <Link underline='hover' color='inherit' href='/contacts'>
                Contacts
              </Link>
              {contact_id ? (
                <Link underline='hover' color='inherit' href={`/contacts/${contact_id}`}>
                  {state.contact?.name ? state.contact.name : contact_id}
                </Link>
              ) : null}
            </Breadcrumbs>
          </Box>
          <Fab
            variant='extended'
            color='primary'
            onClick={() => page(`/canvass/create/${contact_id}`)}
            sx={{
              position: 'fixed',
              bottom: '2rem',
              right: '2rem',
              zIndex: 1000
            }}
          >
            <ContactPage sx={{ mr: 1 }} />
            New Canvass
          </Fab>
          {state.contact ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                // padding: '1rem',
                marginBottom: '10rem',
                gap: '1rem'
              }}
            >
              {state.canvass ? (
                <FormControl sx={{ gap: '1rem', margin: '2rem 1rem 10rem 1rem' }}>
                  <ConfirmSubmitCanvas
                    open={show_confirm}
                    on_close={(to_confirm, cancelled) => {
                      set_show_confirm(false)
                      if (!cancelled) submit_canvass(to_confirm)
                    }}
                    products={state.canvass.farm_details.products}
                  />
                  <h2 style={{ marginBottom: 0 }}>Date</h2>
                  <span>{DateTime.fromISO(state.canvass.date).toFormat('dd MMMM yyyy')}</span>
                  <h2 style={{ marginBottom: 0 }}>Territory Manager</h2>
                  <span>{[state.shed?.tm?.first_name, state.shed?.tm?.last_name].join(' ')}</span>
                  <h2 style={{ marginBottom: 0 }}>Shed</h2>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1rem'
                    }}
                  >
                    <span>{state.shed?.address}</span>
                    <span>{{ north: 'North Island', south: 'South Island' }[state.shed?.island]}</span>
                    <span>{state.shed?.region}</span>
                  </Box>
                  {state.canvass.note_farm ? (
                    <>
                      <h2 style={{ marginBottom: 0 }}>Farm Note</h2>
                      <span>{state.canvass.note_farm}</span>
                    </>
                  ) : null}
                  <CanvassContacts state={state} hub={hub} contact_canditates={contact_canditates} />
                  {state.canvass.agreement_status !== 'No Business' ? (
                    <h2 style={{ marginBottom: 0 }}>Products</h2>
                  ) : null}
                  {state.canvass.farm_details.products.length > 0 &&
                    state.canvass.farm_details.products.map((p, i) => {
                      return (
                        <>
                          <CanvassProductEntry
                            product={products.find(pr => pr.id === p.product_id)}
                            p={p}
                            retailers={data.retailers}
                            products={data.products}
                            state={state.canvass}
                            set_state={canvass => set_state(s => ({ ...s, canvass: canvass(state.canvass) }))}
                            contacts={state.canvass.farm_details.contacts}
                          />
                        </>
                      )
                    })}
                  {state.canvass.agreement_status !== 'No Business' ? (
                    <FormGroup row>
                      <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel id='products-label' htmlFor='products'>
                          Product List
                        </InputLabel>
                        <Select labelId='products-label' id='products' value={''} onChange={add_product}>
                          {Object.keys(grouped_products).map(pk => render_select_group(pk))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  ) : null}

                  {/* Product Status Section */}
                  {state.canvass.farm_details.products.length > 0 && (
                    <>
                      <h2 style={{ margin: '1rem 0 0 0' }}>Product Status</h2>
                      {state.canvass.farm_details.products
                        .reduce((a, c) => [...a, c.category], [])
                        .reduce((a, c) => {
                          {
                            /** All options: 26/02/2024
                             * TEAT-CARE
                             * DET-ACID
                             * DET-ALK
                             * SUP-MIN-TR
                             * DISINFECT
                             */
                          }
                          const agreement_category =
                            { 'TEAT-CARE': 'Teat Spray', 'SUP-MIN-TR': 'Mineral' }[c] ?? 'Other'
                          if (a.includes(agreement_category)) return a
                          return [...a, agreement_category]
                        }, [])
                        .map(category => (
                          <FormGroup row sx={{ gap: '1rem' }}>
                            <Typography variant='h6'>{category}</Typography>
                            <RadioGroup
                              name={`${category}-radio-group`}
                              defaultValue='Pending'
                              value={
                                state.canvass.category_status
                                  ? state.canvass?.category_status[category] ?? 'Pending'
                                  : 'Pending'
                              }
                              onChange={e =>
                                set_state(s => ({
                                  ...s,
                                  canvass: {
                                    ...s.canvass,
                                    category_status: {
                                      ...s.canvass.category_status,
                                      [category]: e.target.value
                                    }
                                  }
                                }))
                              }
                              sx={{
                                flexDirection: 'row'
                              }}
                            >
                              {['Pending', 'Confirmed'].map(option => (
                                <FormControlLabel
                                  key={`${category}_${option}`}
                                  value={option}
                                  control={<Radio />}
                                  label={option}
                                />
                              ))}
                            </RadioGroup>
                          </FormGroup>
                        ))}
                    </>
                  )}

                  {/* Agreement Status Section */}
                  <h2 style={{ marginBottom: 0 }}>Agreement Status</h2>
                  <FormGroup>
                    <RadioGroup
                      aria-labelledby='agreement-status-radio-group-label'
                      name='agreement-status-radio-group'
                      defaultValue={state.canvass.agreement_status}
                      value={state.canvass.agreement_status}
                      onChange={change_agreement_status}
                      sx={{
                        flexDirection: 'row'
                      }}
                    >
                      {agreement_status_options.map(option => {
                        const is_disabled = {
                          Pending: state.canvass.farm_details.products.length === 0,
                          Confirmed: state.canvass.farm_details.products.length === 0,
                          'No Business': state.canvass.farm_details.products.length > 0
                        }[option]
                        return (
                          <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            label={option}
                            disabled={is_disabled}
                          />
                        )
                      })}
                    </RadioGroup>
                  </FormGroup>
                  {state.canvass.agreement_status === 'No Business' ? (
                    <>
                      <h2 style={{ marginBottom: 0 }}>Reason for No Business</h2>
                      <span>{state.canvass.reason_no_business}</span>
                      <div>{state.canvass.note_no_business}</div>
                    </>
                  ) : null}
                  {state.canvass.available_recipients?.length > 0 ? (
                    <>
                      <h2 style={{ margin: '1rem 0 0 0' }}>Resend Agreement to Supply to Recipients</h2>
                      <FormGroup row sx={{ gap: '1rem' }}>
                        {[...state.canvass.available_recipients, state.user.email_address].map(email_address => {
                          const is_added = state.canvass.recipients.includes(email_address)
                          return (
                            <FormControlLabel
                              key={email_address}
                              control={<Checkbox checked={is_added} />}
                              label={email_address}
                              onClick={toggle_recipient(email_address)}
                            />
                          )
                        })}
                      </FormGroup>
                    </>
                  ) : null}
                  {state.canvass.agreement_status !== 'No Business' ? (
                    <FormGroup row sx={{ marginTop: '2rem' }}>
                      <Button
                        variant='contained'
                        onClick={e => set_show_confirm(true)}
                        disabled={should_disable_submit()}
                      >
                        Update Canvass
                      </Button>
                    </FormGroup>
                  ) : null}
                </FormControl>
              ) : null}
            </Box>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: -1
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      )
    }
  ])
})
