import {
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormGroup,
  Menu
} from '@mui/material'
import { Add as AddIcon, Remove, Remove as RemoveIcon } from '@mui/icons-material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useState } from 'react'
import { DateTime } from 'luxon'

const CanvassProductEntry = ({
  product,
  p,
  state: parent_state,
  set_state: parent_set_state,
  retailers,
  products,
  contacts
}) => {
  const unit_price = parseFloat(`${p.price}`.replace(/[^0-9.-]+/g, '')) / p.quantity

  const [state, set_state] = useState({ amount: 1 })
  const [stagger, set_stagger_fn] = useState(p.stagger ?? [])

  const set_stagger = stagger => {
    set_stagger_fn(stagger)
    parent_set_state(s => {
      return {
        ...s,
        farm_details: {
          ...s.farm_details,
          products: s.farm_details.products.map(pp => {
            if (pp.product_id != p.product_id) return pp
            return {
              ...pp,
              stagger
            }
          })
        }
      }
    })
  }

  const validate_quantity = quantity => {
    if (quantity === '') return false
    const re = /^\d{1,}$/
    return re.test(quantity)
  }

  const validate_agreed_price = agreed_price => {
    if (agreed_price === '') return true
    const re = /^\d{2,}(\.\d{2})?$/
    return re.test(agreed_price)
  }

  const validate_date = date => {
    const d = date.replace(/[^\d-]/g, '')
    if (d == '--') return true
    const re = /^\d{2}-\d{2}-\d{4}$/
    return re.test(d)
  }

  const validate = (field, value) => {
    if (field.includes('quantity')) return validate_quantity(value)
    if (field.includes('agreed_price')) return validate_agreed_price(value)
    if (field.includes('date')) return validate_date(value)
  }

  const update_product = (_id, field) => e => {
    parent_set_state(s => ({
      ...s,
      farm_details: {
        ...s.farm_details,
        products: s.farm_details.products.map(p => {
          if (p._id != _id) return p
          if (field.includes('date')) {
            return { ...p, [field]: e?.toFormat('dd-MM-yyyy') }
          }
          if (field == 'quantity') {
            const product = products.find(pp => pp.id == p.product_id)
            return {
              ...p,
              [field]: Number(e.target.value) ? e.target.value : '',
              price: Number(e.target.value)
                ? (product.price * e.target.value)
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'NZD'
                    })
                    .replace('NZ$', '')
                : ''
            }
          }
          if (field == 'retailer_id') {
            const retailer = retailers.find(r => r.id == e.target.value)
            return {
              ...p,
              [field]: e.target.value,
              retailer_name: retailer.name
            }
          }
          return { ...p, [field]: e.target.value }
        })
      }
    }))
  }

  const val_product = (_id, field) => e => {
    const ok = validate(field, e.target.value)
    parent_set_state(s => ({
      ...s,
      farm_details: {
        ...s.farm_details,
        products: s.farm_details.products.map(p => {
          if (p._id != _id) return p
          return { ...p, [`${field}_ok`]: ok }
        })
      }
    }))
  }

  const remove_product = _id => {
    parent_set_state(s => {
      const product_id = s.farm_details.products.find(p => p._id == _id).product_id
      return {
        ...s,
        farm_details: {
          ...s.farm_details,
          products: s.farm_details.products.filter(p => p.product_id != product_id)
        }
      }
    })
  }

  const should_display_ok = v => {
    return v === undefined ? false : v ? false : true
  }

  const do_stagger = () => {
    const { amount } = state
    const {
      quantity: initial_quantity,
      delivery_date: start_delivery_date,
      price: total_price,
      agreed_price: total_agreed_price
    } = {
      ...p,
      price: parseFloat(`${p.price}`.replace(/[^0-9.-]+/g, '')),
      delivery_date: p.delivery_date
        ? DateTime.fromFormat(p.delivery_date, 'dd-MM-yyyy', { zone: 'Pacific/Auckland' })
        : DateTime.now()
    }
    let remaining = initial_quantity
    const stagger = [...Array(amount).keys()].map(i => {
      const date = start_delivery_date
      const quantity = remaining > amount ? remaining - (amount - 1) : 1
      const price = ((parseFloat(total_price) / parseInt(initial_quantity)) * quantity).toFixed(2)
      const agreed_price = total_agreed_price
        ? ((parseFloat(total_agreed_price) / parseInt(initial_quantity)) * quantity).toFixed(2)
        : ''

      remaining -= quantity
      return {
        date,
        quantity,
        price,
        agreed_price
      }
    })
    set_stagger(stagger)
  }

  const add_stagger = () => {
    const { date, quantity, price, agreed_price } = stagger[stagger.length - 1]
    set_stagger([...stagger, { date, quantity, price, agreed_price }])
  }

  const remove_stagger = i => set_stagger(stagger.filter((_, index) => index != i))

  const clear_stagger = () => set_stagger([])

  const available_preferred_contacts = contacts.map(c => c.retailer_id).filter(r => r)

  const MAX_DEFAULT_STAGGER = 20

  return (
    <FormGroup key={p._id} sx={{ gap: '1rem', marginBottom: '1rem' }}>
      <h3 style={{ margin: 0 }}>{`${product.name} ${product.pack_size}L`}</h3>
      <FormGroup row sx={{ gap: '1em', alignItems: 'center' }}>
        <Button variant='contained' onClick={e => remove_product(p._id)}>
          Remove
        </Button>
        {parseInt(p.quantity) > 1 && (
          <>
            |
            <Button variant='contained' onClick={do_stagger}>
              Stagger
            </Button>
            over
            <Select value={state.amount} onChange={e => set_state(s => ({ ...s, amount: parseInt(e.target.value) }))}>
              {Array.from(Array(Math.min(parseInt(p.quantity), MAX_DEFAULT_STAGGER)).keys()).map((_, i) => (
                <MenuItem value={i + 1} key={`stagger_amount_${i + 1}_${p._id}`}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
            deliveries &nbsp;
          </>
        )}
        {stagger.length > 0 && (
          <>
            |
            <Button variant='contained' onClick={clear_stagger}>
              Stop Stagger
            </Button>
          </>
        )}
      </FormGroup>
      <FormGroup row sx={{ gap: '1rem' }}>
        <TextField
          label='Product Quantity'
          value={stagger.length > 0 ? stagger.reduce((acc, s) => acc + parseInt(s.quantity), 0) : p.quantity}
          disabled={stagger.length > 0}
          onChange={update_product(p._id, 'quantity')}
          onBlur={val_product(p._id, 'quantity')}
          error={should_display_ok(p.quantity_ok)}
          helperText={should_display_ok(p.quantity_ok) && 'Invalid product quantity.'}
        />
        <TextField
          label='Price'
          value={stagger.length > 0 ? stagger.reduce((acc, s) => acc + s.quantity * unit_price, 0) : p.price}
          onChange={update_product(p._id, 'price')}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>
          }}
          disabled={true}
          sx={{ maxWidth: 200 }}
        />
        <TextField
          label='Agreed Price'
          value={
            stagger.length > 0
              ? stagger.map(s => s.agreed_price).filter(s => s).length == stagger.length
                ? stagger
                    .reduce((acc, s) => acc + parseFloat(`${s.agreed_price}`.replace(/[^0-9.-]+/g, '')), 0)
                    .toFixed(2)
                : ''
              : p.agreed_price ?? ''
          }
          disabled={stagger.length > 0}
          onChange={update_product(p._id, 'agreed_price')}
          onBlur={val_product(p._id, 'agreed_price')}
          error={should_display_ok(p.agreed_price_ok)}
          helperText={should_display_ok(p.agreed_price_ok) && 'Invalid agreed price.'}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>
          }}
          sx={{ maxWidth: 200 }}
        />
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            label='Delivery Date'
            format='dd-MM-yyyy'
            defaultValue={p.delivery_date ? DateTime.fromFormat(p.delivery_date, 'dd-MM-yyyy') : null}
            onChange={update_product(p._id, 'delivery_date')}
            slotProps={{
              textField: {
                onBlur: val_product(p.product_id, 'delivery_date'),
                error: should_display_ok(p.delivery_date_ok),
                helperText: should_display_ok(p.delivery_date_ok) && 'Invalid delivery date.'
              }
            }}
            disabled={stagger.length > 0}
            sx={{ maxWidth: 200 }}
          />
        </LocalizationProvider>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id='region-select-label'>Retailer</InputLabel>
          <Select
            labelId='region-select-label'
            id='region-select'
            value={p.retailer_id ? p.retailer_id : ''}
            onChange={update_product(p._id, 'retailer_id')}
          >
            {available_preferred_contacts.map(retailer_id => {
              const retailer = retailers.find(r => r.id === retailer_id)
              return (
                <MenuItem key={retailer_id} value={retailer_id}>
                  {retailer?.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </FormGroup>
      {stagger &&
        stagger.map((s, i) => (
          <FormGroup row sx={{ gap: '1rem' }} key={`staggerline_${p._id}_${i}`}>
            <IconButton
              onClick={e => {
                e.preventDefault()
                if (i == stagger.length - 1) add_stagger()
                else remove_stagger(i)
              }}
            >
              {i == stagger.length - 1 ? <AddIcon /> : <RemoveIcon />}
            </IconButton>
            <TextField
              label='Product Quantity'
              value={stagger[i].quantity}
              onBlur={val_product(p._id, 'quantity')}
              error={should_display_ok(p.quantity_ok)}
              helperText={should_display_ok(p.quantity_ok) && 'Invalid product quantity.'}
              onChange={e => {
                const quantity = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                return set_stagger(
                  stagger.map((s, index) =>
                    index == i
                      ? {
                          ...s,
                          quantity,
                          price: (quantity * unit_price).toFixed(2)
                        }
                      : s
                  )
                )
              }}
            />
            <TextField
              label='Price'
              value={stagger[i].price}
              disabled={true}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>
              }}
              sx={{ maxWidth: 200 }}
            />
            <TextField
              label='Agreed Price'
              value={stagger[i].agreed_price ? stagger[i].agreed_price : ''}
              onBlur={val_product(p._id, 'agreed_price')}
              error={should_display_ok(p.agreed_price_ok)}
              helperText={should_display_ok(p.agreed_price_ok) && 'Invalid agreed price.'}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>
              }}
              sx={{ maxWidth: 200 }}
              onChange={e => {
                const agreed_price = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0
                set_stagger(stagger.map((s, index) => (index == i ? { ...s, agreed_price } : s)))
              }}
            />
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label='Delivery Date'
                format='dd-MM-yyyy'
                value={stagger[i].date}
                onChange={date => {
                  set_stagger(stagger.map((s, index) => (index == i ? { ...s, date } : s)))
                }}
                slotProps={{
                  textField: {
                    onBlur: val_product(p.product_id, 'delivery_date'),
                    error: should_display_ok(p.delivery_date_ok),
                    helperText: should_display_ok(p.delivery_date_ok) && 'Invalid delivery date.'
                  }
                }}
                sx={{ maxWidth: 200 }}
              />
            </LocalizationProvider>
          </FormGroup>
        ))}
    </FormGroup>
  )
}

export default CanvassProductEntry
