import inject from 'seacreature/lib/inject'
import { useContext, useState, useEffect } from 'react'
import { Grid, Box, Button, TextField } from '@mui/material'
import page from 'page'

inject('pod', ({ HubContext, StateContext }) => {
  inject('route', [
    '/',
    p => () => {
      const hub = useContext(HubContext)
      const { online, has_log_in_error, logged_in } = useContext(StateContext)
      const [state, set_state] = useState({})

      const log_in = () => async e => {
        e.preventDefault()
        hub.emit('log in', state)
      }

      const rem = f => e => {
        e.preventDefault()
        set_state(s => ({ ...s, [f]: e.target.value }))
      }

      const validate_email = email => {
        if (email === '') return true
        const re = /\S{2,}@\S{2,}\.\S{2,}/
        return re.test(email)
      }

      const should_disable_submit = () => {
        if (!online) return true
        const { username, password } = state
        return !validate_email(username) || !password
      }

      useEffect(() => {
        if (logged_in) page('/contacts')
      }, [logged_in])

      return (
        <Grid
          container
          sx={{
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {online ? (
            <Grid
              item
              xs={9}
              md={3}
              sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'center',
                gap: '1rem',
                height: '100%',
                padding: '1rem'
              }}
            >
              <TextField
                variant='outlined'
                size='small'
                type='email'
                placeholder='Email'
                onChange={rem('username')}
                fullWidth
              />
              <TextField
                variant='outlined'
                size='small'
                type='password'
                placeholder='Password'
                onChange={rem('password')}
                fullWidth
              />
              <Button variant='contained' onClick={log_in()} disabled={should_disable_submit()}>
                Login
              </Button>
              {has_log_in_error ? (
                <Box sx={{ color: 'red', textAlign: 'center' }}>
                  <p>Invalid email or password.</p>
                </Box>
              ) : null}
            </Grid>
          ) : (
            <p>Try logging in again once back online.</p>
          )}
        </Grid>
      )
    }
  ])
})
